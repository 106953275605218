import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import { cardOperatorLogos } from '../Utilities/Placeholders';
import { CreditCard, Edit } from '@mui/icons-material';

function CardTile({ cardNumber, isSelected, isDefault, onClick, disabled, ...props }) {
    const [last4Digits, setLast4Digits] = useState('');
    const [cardOperator, setCardOperator] = useState('null');

    const getCardOperator = (cardNumber) => {
        const number = cardNumber.replace(/\D/g, ''); // Remove non-digit characters
    
        if (/^(4011|4312|4389|4514|4576|5041|5067|5090|6277|6362|6363|650[0-9]|6516|655[0-5])/.test(number)) {
            return 'elo';
        } else if (/^4/.test(number)) {
            return 'visa';
        } else if (/^5[1-5]/.test(number)) {
            return 'mastercard';
        } else if (/^3[47]/.test(number)) {
            return 'amex';
        } else if (/^5067/.test(number)) {
            return 'alelo';
        } else if (/^(6062|637[0-4])/.test(number)) {
            return 'hipercard';
        } else if (/^(300[0-5]|36|38)/.test(number)) {
            return 'diners';
        } else if (/^(2131|1800|35[2-8][0-9])/.test(number)) {
            return 'jcb';
        } else {
            return 'null';
        }
    };

    const getLast4Digits = (cardNumber) => {
        const digitsOnly = cardNumber.replace(/\D/g, ''); // Remove non-digit characters
        return digitsOnly.slice(-4); // Get the last 4 digits
    };

    useEffect(() => {
        setLast4Digits(getLast4Digits(cardNumber));
        setCardOperator(getCardOperator(cardNumber));
    }, [cardNumber]);

    return (
        <>
            {!disabled 
                ?
                    <div 
                        className={`row defaultBorderedItem clickArea ${isSelected ? 'selectedItem' : 'itemSelect'}`} 
                        onClick={onClick} 
                        style={{ gap: '10px', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <div className='row' style={{ alignItems: 'center', gap: '10px' }}>
                            <span><span className='grayedText'>•••• </span>{last4Digits}</span>
                            {cardOperator !== 'null'
                            ? 
                                <img src={cardOperatorLogos[cardOperator]} alt={`${cardOperator} logo`} style={{height: '24px'}}/>
                            :
                                <CreditCard style={{color: 'gray'}}/>
                            }
                        </div>
                        {isDefault &&
                            <span>Padrão</span>
                        }
                        <div className='row center'>
                            <Edit/>
                            <span>Alterar</span>
                        </div>
                    </div>
                :
                <div 
                    className='row defaultBorderedItem' 
                    style={{ gap: '10px', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <div className='row' style={{ alignItems: 'center', gap: '10px' }}>
                        <span><span className='grayedText'>•••• </span>{last4Digits}</span>
                        {cardOperator !== 'null'
                        ? 
                            <img src={cardOperatorLogos[cardOperator]} alt={`${cardOperator} logo`} style={{height: '24px'}}/>
                        :
                            <CreditCard style={{color: 'gray'}}/>
                        }
                    </div>
                    {isDefault &&
                        <span>Padrão</span>
                    }
                </div>
            }
        </>
    );
}

CardTile.propTypes = {
    cardNumber: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CardTile;