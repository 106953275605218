export const defaultStore = "GoParts";
export const defaultStoreLink = "goparts.com.br";
export const defaultContactEmail = 'contato@goparts.com.br';

export const storeSellersWarehouses = [
    "Globo Veículos Alto da XV",
    "Globo Nissan Alto da XV",
    "GLOBO JEEP CURITIBA",
    "Nissul Pelotas",
    "NISSUL GALA JEEP BAGÉ",
    "Iesa Ipiranga",
    "Itavema France Pinheiros",
    "ITVA FIAT NOVA IGUACU",
    "TOYOTA BOTAFOGO",
    "Iesa NISSAN LOG",
    "ITVA HYUNDAI CAMPO GRANDE",
    "NISSUL GALA HYUNDAI PELOTAS",
    "SAVOL VW CENTRO",
    "REVEMAR FIAT BELEM",
    "UNIQUE ANANINDEUA",
    "TROPICAL SÃO LUIS",
    "TROPICAL IMPERATRIZ",
    "Iesa VOLVO Azenha",
    "ITVA VOLVO VILA OLIMPIA",
    "BELCAR ASUKA",
    "Du Nort Belém - Marco",
    "IESA MOPAR",
    "SAVOL TOYOTA",
    "Iesa BMW NH",
    "BELCAR VW",
    "BELCAR FIAT",
    "REVEMAR VW",
    "SAVOL PSA",
    "SAVOL FIAT",
    "SAVOL KIA",
    "FENIX BELEM",
    "DISCAUTOL VW CARANDA",
    "REVEMAR VW PARAUAPEBAS",
    "REVEMAR FIAT - SÃO LUIS",
    "REVEMAR PSA BELEM",
    "FENIX PARAUAPEBAS",
    "TOYOTA GASTÃO VIDIGAL",
    "Itavema France Guarulhos",
    "Du Nort São Luís",
    // "FENIX MARABÁ",
    "TROPICAL ANANINDEUA",
    "TROPICAL DOCA",
    "VECOL VW",
    "TEXAS",
    "Du Nort Marabá",
    "Du Nort Parauapebas",
    "TROPICAL MARABA",
    "TERRA SANTA",
    "Fórmula Curitiba - Parolin",
    "FABERGE KIA",
    "FABERGE VOLVO MOGI",
    "FABERGE PSA OFICINA",
    "RENAULT FABERGE",
    "FIAT FABERGE",
    "FABERGE AUDI MOGI",
    "GLOBO RENAULT SC",
    "GLOBO JEEP SC",
    "GLOBO FORD SC",
    "VIGORITO VW ATACADO",
    "VIGORITO NISSAN ATACADO",
    "ITAVEMA JAPAN GASTAO VIDIGAL",
    "VIGORITO KIA",
    "BARI PONTA GROSSA HYUNDAI",
    "BARIGUI HYUNDAI SC",
    "BARIGUI NISSAN",
    "GLOBO FIAT SC",
    "VIGORITO GM ATACADO",
    "NIX NISSAN BLUMENAU",
    "NIX NISSAN JOINVILLE",
    "UMUARAMA FIAT ARAGUAINA",
    "UMUARAMA FIAT PARAUAPEBAS",
    "UMUARAMA FIAT REDENCAO",
    "UMUARAMA TOYOTA ARAGUAINA",
    "UMUARAMA TOYOTA IMPERATRIZ",
    "UMUARAMA TOYOTA RIO VERDE",
    "UMUARAMA VW ARAGUAINA",
    "UMUARAMA VW ITUMBIARA",
    "UMUARAMA VW PALMAS",
    "UMUARAMA KIA",
    "UMUARAMA CITROEN RIO VERDE",
    "UMUARAMA TOYOTA BALSAS",
    "UMUARAMA TOYOTA CALDAS NOVAS",
    "UMUARAMA TOYOTA MINEIROS",
    "UMUARAMA TOYOTA ITUMBIARA",
    "RECREIO BH VW",
    "RECREIO RJ VW",
    'SULPAR SGA TOYOTA - HAUER',
    'SULPAR SGA TOYOTA - NITEROI',
    'VITORIAWAGEN SERRA',
    'UMUARAMA HARLEY GOIANIA',
    'VIGORITO VW SAO BERNARDO',
    'VIGORITO VW MAUA',
    'VIGORITO VW VILA MARIANA',
    'VIGORITO NISSAN PENHA',
    'VIGORITO NISSAN GUARULHOS',
    'VIGORITO KIA ARICANDUVA',
    // 'DUVEL - VEICULOS CENTRO',
    "LUSON VW",
    "LUSON VW TARUMA",
    "Iesa NISSAN Canoas",
    "Iesa NISSAN Gravataí",
    "Iesa NISSAN NH",
    "Iesa NISSAN Osório",
    "Iesa NISSAN Tarso",
    "IESA NISSAN WENCESLAU",
    "Iesa JEEP Cachoeirinha",
    "Iesa HARLEY Poa",
    "Carmo Montes Claros",
    "Open Veículos Cascavel",
    "VETOR HYUNDAI CASCAVEL",
    "BRIZZA MITSUBISHI CASCAVEL",
    "SAINT-TROPEZ PEUGEOT SANTOS",
    "AKTA KIA",
];