export async function createUser(userInfo) {
	const apiUrl = 'https://us-central1-pocrenault-daa71.cloudfunctions.net/createUser';

	const json =
	{
        "uid": userInfo['uid'],
		"email": userInfo['email'],
		"name": userInfo['name'],
		"cep": userInfo['cep'],
	};

	const response = await fetch(apiUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(json)
	});

	if (!response.ok) {
		throw new Error('Failed to create user');
	}

	const data = await response.json();
	return data;
}

export async function loadAllCars() {
	const apiUrl = 'https://us-central1-pocrenault-daa71.cloudfunctions.net/loadAllCars';

	const response = await fetch(apiUrl, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (!response.ok) {
		throw new Error('Failed to load cars');
	}

	const data = await response.json();
	return data;
}

export async function loadOEbyCarCodeType(internalID) {
	const apiUrl = 'https://us-central1-pocrenault-daa71.cloudfunctions.net/loadOEbyCarCodeType';

	const json = {
		'internalID': internalID
	}

	const response = await fetch(apiUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(json)
	});

	if (!response.ok) {
		throw new Error('Failed to load OEs');
	}

	const data = await response.json();
	return data;
}

export async function getPartsListFromWH(info) {
	const apiUrl = 'https://us-central1-pocrenault-daa71.cloudfunctions.net/getPartsListFromWH';

	const response = await fetch(apiUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(info)
	});

	if (!response.ok) {
		throw new Error('Failed to get parts list from WH');
	}

	const data = await response.json();
	return data;
}

export async function getCategoryWithIdProd(id_prod) {
	const apiUrl = 'https://us-central1-pocrenault-daa71.cloudfunctions.net/getCategoryWithIdProd';

	const json = {
		'id_prod': id_prod
	}

	const response = await fetch(apiUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(json)
	});

	if (!response.ok) {
		throw new Error('Failed to get category with id_prod');
	}

	const data = await response.json();
	return data;
}