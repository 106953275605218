import * as API from '../api';

export const CREATE_USER = 'CREATE_USER';

const estadoInicial = {
    usuario: []
}

const user = (state = estadoInicial, action) => {
    switch (action.type) {
        case CREATE_USER:
            return { ...state, usuario: action.payload };
        default:
            return state;
    }
}

export const createUser = (info) => async (dispatch) => {
	return (
		dispatch({
			type: CREATE_USER,
			payload: API.createUser(info).then((result) => {
				return result; 
			}),
		})
	);
};

export default user;