import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion } from '../reducers/vehicle'; // Import actions from vehicle.js
import '../App.css';
import { auth } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../Common/Button';
import ModelImgPH from '../images/models/8604.png';
import { Close, Edit, Logout, Add, Remove, Person, CreditCard, HomeWork, ArrowBackSharp } from '@mui/icons-material';
import TabSelector from '../Common/TabSelector';
import PedidosComponent from '../Orders/PedidosComponent';
import { UfList, UserAddresses, ShippingOptions, userCreditCards, userCreditCards as initialUserCreditCards, validCoupons, userData } from '../Utilities/Placeholders'; // Ensure the import path is correct
import AddressTile from '../Checkout/AddressTile';
import CardTile from '../Checkout/CardTile';
import ExpandDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandUpIcon from "@mui/icons-material/ArrowDropUp";
import antifraudIcon from '../images/Icone_Antifraude.png';
import { contentData, configData } from '../Whitelabel/websitesData';
import { DealerServices } from '../Utilities/DealerServices';
import { formatPercent, formatPrice } from '../Utilities/Formats';
import { useUser } from '../Providers/UserContext';
import ClickOutside from '../Utilities/ClickOutside';
import { getAddress } from '../Utilities/logisticservices';
import { RESET_FROM_CART } from '../reducers/cart';

function MyAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const updatedVehicle = useSelector(state => state.vehicle.userVehicles);
  const {gpuser,setUser} = useUser();
  const [addresses, setAddresses] = useState([]);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [editingAddressOptions, setEditingAddressOptions] = useState(false);
  const [showCustomAddress, setShowCustomAddress] = useState(false);
  const [showCustomAddressEdit, setShowCustomAddressEdit] = useState(true);

  const [addingNewCard, setAddingNewCard] = useState(() => initialUserCreditCards.length === 0);

  const [cardNumber, setCardNumber] = useState('');
  const [validThru, setValidThru] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [clientDoc, setClientDoc] = useState('');
  const [phone, setPhone] = useState('');

  const [isCardNumberValid, setIsCardNumberValid] = useState(null);
  const [isCardHolderNameValid, setIsCardHolderNameValid] = useState(null);
  const [isValidThruValid, setIsValidThruValid] = useState(null);
  const [isVerificationCodeValid, setIsVerificationCodeValid] = useState(null);
  const [isClientDocValid, setIsClientDocValid] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(null);

  const [isAddingPayAddress, setIsAddingPayAddress] = useState(false);
  const [newPayAddressSelected, setNewPayAddressSelected] = useState(false);
  const [userCreditCards, setUserCreditCards] = useState(initialUserCreditCards);

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const defaultAddressIndex = UserAddresses.findIndex((address) => address.isDefault);

  const dispatch = useDispatch();
  const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);
  const handleEditVehicle = () => {
      dispatch(setIsSelectingVehicle(true));
  }

  const tabs = configData.defaultStore === 'UmuaramaHD'
    ? ['Meus dados', 'Pedidos']
    : ['Meus dados', 'Pedidos', 'Serviços'];
    
  const [selectedTab, setSelectedTab] = useState('Meus dados');
  // const [selectedTab, setSelectedTab] = useState('Meus dados');

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    // Update the URL hash without reloading the page
    navigate(`#${tab.toLowerCase().replace(' ', '-')}`);
  };

  const userDataView = ['personal', 'addresses', 'creditCards'];
  const [selectedUserDataView, setSelectedUserDataView] = useState('personal');
  const [isEditingUserData, setIsEditingUserData] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const handleUserDataViewSelect = (view) => {
    setSelectedUserDataView(view);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    if (!gpuser || gpuser == null) {
      // Redirect to login if the user is not authenticated
      navigate('/login');
      return;
    }
  }, [gpuser]);


  const logout = async () => {
    const isConfirmed = window.confirm(`Deseja sair da conta ${gpuser.email}?`);
  
    if (isConfirmed) {
      try {
        // localStorage.clear();
        // sessionStorage.clear();
        
        // Reset user context if you're using it
        setUser(null);  // Add this if you're using UserContext
        
        // Sign out from Firebase
        await auth.signOut();
        
        // Clear cart
        dispatch({
          type: RESET_FROM_CART,
          payload: {}
        });

        // Force a page reload after logout
        window.location.href = '/';  // Use this instead of navigate
      } catch (error) {
        console.error('Error during logout:', error);
      }
    }
  };

  const [errors, setErrors] = useState({});

  const [newAddress, setNewAddress] = useState({
    name: '',
    street: '',
    streetNumber: '',
    additionalAddress: '',
    neighborhood: '',
    city: '',
    uf: '',
    cep: '',
  });

  const [editAddress, setEditAddress] = useState({
    name: '',
    street: '',
    streetNumber: '',
    additionalAddress: '',
    neighborhood: '',
    city: '',
    uf: '',
    cep: '',
  });

  const editExistingAdress = (address) => {
    
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const [newPayAddress, setNewPayAddress] = useState({
    street: '',
    streetNumber: '',
    additionalAddress: '',
    neighborhood: '',
    city: '',
    uf: '',
    cep: '',
  });

  const addNewPayAddress = () => {
    setIsAddingPayAddress(true);
    setNewPayAddressSelected(false);
  }

  const getSelectedAddress = () => {
    if (selectedAddressIndex !== null && selectedAddressIndex >= 0 && gpuser && selectedAddressIndex < gpuser.address.length) {
      return gpuser.address[selectedAddressIndex];
    }
    return null; // Handle cases where selectedAddressIndex is out of bounds or not set
  };

  const selectedAddress = getSelectedAddress();

  const handleAddNewCard = (value) => {
    scrollToTop();
    setAddingNewCard(value);
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 16) {
      const formattedValue = value.replace(/(\d{4})(?=\d)/g, '$1 ');
      setCardNumber(formattedValue);
  
      // Validate card number
      const isValid = validateCardNumber(formattedValue);
      setIsCardNumberValid(isValid);
      //console.log('Card Number Valid:', isValid);
    }
  };

  const handleValidThruChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 4) {
      const formattedValue = value.replace(/(\d{2})(?=\d)/, '$1/');
      setValidThru(formattedValue);
  
      // Validate valid thru date
      const isValid = validateValidThru(formattedValue);
      setIsValidThruValid(isValid);
      //console.log('Valid Thru Valid:', isValid);
    }
  };

  const handleVerificationCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 4) {
      setVerificationCode(value);
  
      // Validate verification code
      const isValid = validateVerificationCode(value);
      setIsVerificationCodeValid(isValid);
      //console.log('Verification Code Valid:', isValid);
    }
  };

  const handleCardHolderNameChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
    setCardHolderName(value);
  
    // Validate card holder name
    const isValid = validateCardHolderName(value);
    setIsCardHolderNameValid(isValid);
    //console.log('Card Holder Name Valid:', isValid);
  };

  const handleClientDocChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
  
    // Limit the length to 14 characters (CNPJ max length)
    if (value.length > 14) {
      value = value.slice(0, 14);
    }
  
    let formattedValue = value;
  
    if (value.length <= 11) {
      // Format as CPF: 000.000.000-00
      formattedValue = value.replace(/(\d{3})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // Format as CNPJ: 00.000.000/0000-00
      formattedValue = value.replace(/(\d{2})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1.$2')
                            .replace(/(\d{3})(\d)/, '$1/$2')
                            .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    }
  
    setClientDoc(formattedValue);
  
    // Validate client document
    const isValid = validateClientDoc(formattedValue);
    setIsClientDocValid(isValid);
    //console.log('Client Doc Valid:', isValid);
  };

  const validateCardNumber = (number) => {
    // Remove spaces or hyphens if present
    const cleanedNumber = number.replace(/[\s-]/g, '');
  
    // Check if the cleaned card number is a string of digits and has the right length
    const regex = /^\d{13,19}$/;
    return regex.test(cleanedNumber);
  };
  
  const validateCardHolderName = (name) => {
    // Check if the name is a string with at least one character
    const regex = /^[A-Za-z\s]+$/; // Allow letters and spaces
    return regex.test(name) && name.trim().length > 0;
  };
  
  const validateValidThru = (thru) => {
    // Check if the date is in MM/YY format
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!regex.test(thru)) {
      return false;
    }
  
    // Extract month and year
    const [month, year] = thru.split('/').map(num => parseInt(num, 10));
    const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
    const currentMonth = new Date().getMonth() + 1; // Current month (1-indexed)
  
    // Check if the card is not expired
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return false;
    }
  
    return true;
  };
  
  const validateVerificationCode = (code) => {
    // Check if the verification code is a 3 or 4 digit number
    const regex = /^\d{3,4}$/;
    return regex.test(code);
  };

  const validateClientDoc = (doc) => {
    // Remove caracteres de formatação
    const cleanedDoc = doc.replace(/\D/g, '');
    
    // Verifica se o comprimento do documento é 11 (CPF) ou 14 (CNPJ)
    return cleanedDoc.length === 11 || cleanedDoc.length === 14;
  };

  const validatePhone = (phone) => {
    // Remove caracteres de formatação
    const cleanedPhone = phone.replace(/\D/g, '');
    
    // Números de telefone brasileiros devem ter 10 ou 11 dígitos
    return cleanedPhone.length === 10 || cleanedPhone.length === 11;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    let formattedValue = value;
  
    if (value.length > 2 && value.length <= 6) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2)}`;
    } else if (value.length > 6 && value.length <= 10) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2, 6)}-${value.slice(6)}`;
    } else if (value.length > 10) {
      formattedValue = `(${value.slice(0, 2)})${value.slice(2, 7)}-${value.slice(7, 11)}`;
    }
  
    setPhone(formattedValue);
  
    // Validate phone number
    const isValid = validatePhone(formattedValue);
    setIsPhoneValid(isValid);
    //console.log('Client Phone Valid:', isValid);
  };

  const handleNewPayAddressInputChange = (e) => {
    const { id, value } = e.target;
    setNewPayAddress((prevState) => ({
      ...prevState,
      cep: formattedCep,
      [id]: value,
    }));
  };

  const handleNewPayAddressSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      //console.log('New pay address:', newPayAddress);
      setNewPayAddressSelected(true);
      setIsAddingPayAddress(false);
    }
  };

  const saveNewCard = () => {
    // Perform validation checks
    const cardNumberValid = validateCardNumber(cardNumber);
    const cardHolderNameValid = validateCardHolderName(cardHolderName);
    const validThruValid = validateValidThru(validThru);
    const verificationCodeValid = validateVerificationCode(verificationCode);
    const clientDocValid = validateClientDoc(clientDoc);
    const phoneValid = validatePhone(phone);
  
    // Update validation state
    setIsCardNumberValid(cardNumberValid);
    setIsCardHolderNameValid(cardHolderNameValid);
    setIsValidThruValid(validThruValid);
    setIsVerificationCodeValid(verificationCodeValid);
    setIsClientDocValid(clientDocValid);
    setIsPhoneValid(phoneValid);
  
    // Check overall form validity
    if (
      cardNumberValid &&
      cardHolderNameValid &&
      validThruValid &&
      verificationCodeValid &&
      clientDocValid &&
      phoneValid
    ) {
      // Create a new card object and save it
      const newCard = {
        cardNumber,
        cardHolderName,
        validThru,
        verificationCode,
        clientDoc,
        phone,
        isDefault: false,
        address: {},
        saveToLogin: false,
      };
  
      setUserCreditCards((prevCards) => [newCard, ...prevCards]);
      setCardNumber('');
      setCardHolderName('');
      setValidThru('');
      setVerificationCode('');
      setClientDoc('');
      setPhone('');
  
      // Save address data into purchaseData.payment.card.address
      if (newPayAddressSelected) {
        newCard.address = newPayAddress;
      } else {
        newCard.address = selectedAddress;
      }
  
      newCard.saveToLogin = true;

      handleAddNewCard(false);
      scrollToTop();
    } else {
      scrollToTop();
      //console.log('Form is invalid');
    }
  };

  const validateForm = () => {
    const { street, streetNumber, neighborhood, city, uf } = newPayAddress;
    const newErrors = {
      street: validateField('street', street),
      streetNumber: validateField('streetNumber', streetNumber),
      neighborhood: validateField('neighborhood', neighborhood),
      city: validateField('city', city),
    };

    // Validate UF against UfList
    if (!UfList[newPayAddress.uf]) {
      newErrors.uf = '*';
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  useEffect(() => {
  }, [gpuser]);

  const existingAddressOptions = (index) => {
    const selectedAddress = gpuser.address[index];
  
    // Update the state with the selected address details
    setEditAddress({
      name: selectedAddress.name || '',
      street: selectedAddress.street || '',
      streetNumber: selectedAddress.number || '',
      additionalAddress: selectedAddress.additionalAddress || '',
      neighborhood: selectedAddress.district || '',
      city: selectedAddress.city || '',
      uf: selectedAddress.state || '',
      cep: selectedAddress.zipCode || ''
    });
    setCep(selectedAddress.zipCode);
    setFormattedCep(selectedAddress.zipCode);
  
    setEditingAddressOptions(true);
  };

  const [cep, setCep] = useState('');
  const [formattedCep, setFormattedCep] = useState('');

  const validateFormNewAddress = () => {
    const { street, streetNumber, neighborhood, city, uf } = newAddress;
    const newErrors = {
      street: validateField('street', street),
      streetNumber: validateField('streetNumber', streetNumber),
      neighborhood: validateField('neighborhood', neighborhood),
      city: validateField('city', city),
    };

    // Validate UF against UfList
    if (!UfList[newAddress.uf]) {
      newErrors.uf = '*';
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const validateFormEditAddress = () => {
    const { street, streetNumber, neighborhood, city, uf } = editAddress;
    const newErrors = {
      street: validateField('street', street),
      streetNumber: validateField('streetNumber', streetNumber),
      neighborhood: validateField('neighborhood', neighborhood),
      city: validateField('city', city),
    };

    // Validate UF against UfList
    if (!UfList[editAddress.uf]) {
      newErrors.uf = '*';
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleNewAddressInputChange = (e) => {
    const { id, value } = e.target;
    setNewAddress((prevState) => ({
      ...prevState,
      cep: formattedCep,
      [id]: value,
    }));
  };

  const handleEditAddressInputChange = (e) => {
    const { id, value } = e.target;
    setEditAddress((prevState) => ({
      ...prevState,
      cep: formattedCep,
      [id]: value,
    }));
  };

  const handleCepChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    setCep(value);

    let formattedValue = value;
    if (value.length > 2 && value.length <= 5) {
      formattedValue = `${value.slice(0, 2)}.${value.slice(2)}`;
    } else if (value.length > 5) {
      formattedValue = `${value.slice(0, 2)}.${value.slice(2, 5)}-${value.slice(5, 8)}`;
    }
  
    setFormattedCep(formattedValue);
  };

  const searchCep = async (cep) => {
    let newaddress = await getAddress(cep)
    if (cep.length === 8) {
      setNewAddress({
        name: newaddress.name || '',
        street: newaddress.logradouro || '',
        streetNumber: newaddress.number || '',
        additionalAddress: '',
        neighborhood: newaddress.bairro || '',
        city: newaddress.cidade || '',
        uf: newaddress.estado || '',
        cep: newaddress.cep || ''
      });
      setShowCustomAddress(true);
      setShowCustomAddressEdit(true);
    } else {
      setShowCustomAddress(false);
      setShowCustomAddressEdit(false);
    }
  };

  const validateField = (field, value) => {
    let errorMessage = '';
    if (!value) {
      errorMessage = 'Campo obrigatório';
    }
    return errorMessage;
  };

  const handleNewAddressSubmit = (e) => {
    e.preventDefault();
    if (validateFormNewAddress()) {
      //console.log('New logged address:', newAddress);

      gpuser.setAddress(newAddress);
      // alert('!salvar endereço no login para aparecer na lista de endereços!');
      setIsAddingNewAddress(false);
    }
  };

  const handleEditAddressSubmit = (e) => {
    e.preventDefault();
    if (validateFormEditAddress()) {
      //console.log('edit existing address:', editAddress);
      alert('!salvar edição de endereço existente no login!');
      setIsEditingAddress(false);
    }
  };

  useEffect(() => {
    if (selectedTab == 'Meus dados') {
      setSelectedUserDataView('personal');
    }
  }, [selectedTab]);
  
  useEffect(() => {
    const hash = window.location.hash.substring(1); // Extract hash
    const tabMapping = {
      'meus-dados': 'Meus dados',
      'pedidos': 'Pedidos',
      'veiculos': 'Serviços'
    };

    // Set selected tab based on hash
    if (tabMapping[hash]) {
      setSelectedTab(tabMapping[hash]);
    }

    // Scroll to the element if it exists
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]); // Depend on location.hash

  const handleDataEdit = () => {
    setIsEditingUserData(true);
  };

  const handlePasswordEdit = () => {
    setIsEditingPassword(true);
  };

  const handlePasswordSave = () => {
    const isConfirmed = window.confirm('Tem certeza que deseja alterar sua senha?');
  
    if (isConfirmed) {
      setIsEditingPassword(false);
      // You can also add other actions like saving the password here
    }
  };

  const handlePasswordCancel = () => {
    setIsEditingPassword(false);
  }

  const handleDataSave = () => {
    const isConfirmed = window.confirm('Tem certeza que deseja alterar seus dados?');
  
    if (isConfirmed) {
      setIsEditingUserData(false);
    }
  };

  const handleDataCancel = () => {
    setIsEditingUserData(false);
  };

  const openVehicleSelect = () => {
    dispatch(setIsSelectingVehicle(!isSelectingVehicle));
  };

  const handleAddNewAddress = () => {
    setEditAddress({
      name: '',
      street: '',
      streetNumber: '',
      additionalAddress: '',
      neighborhood: '',
      city: '',
      uf: '',
      cep: '',
    });
    setCep('');
    setFormattedCep('');
    setShowCustomAddress(false);
    setIsAddingNewAddress(true);
  };

  return (
    <div className="main fadeIn">
      {gpuser &&
        <div className='pageContentSmall'>
            {isAddingNewAddress &&
              <div className='defaultDialog fadeIn'>
                <ClickOutside onOutsideClick={() => setIsAddingNewAddress(false)}>
                  <div className='column cardFormContainer defaultDialogContainer' style={{gap: '10px'}}>
                    <h2>Novo endereço</h2>
                    {!showCustomAddress &&
                      <div className='row fadeIn' style={{ gap: '10px' }}>
                        <input
                          id='CEP'
                          type='text'
                          placeholder='00.000-000'
                          value={formattedCep}
                          onChange={handleCepChange}
                          onKeyDown={(e) => e.key === 'Enter' && searchCep(cep)}
                        />
                        <Button
                          className='buttonColorMain smallButton'
                          // iconEnd={<CouponIcon />}
                          label='Buscar CEP'
                          onClick={() => searchCep(cep)}
                        />
                      </div>
                    }
                    {showCustomAddress &&
                      <div className='column fadeIn' style={{gap: '10px'}}>
                        <div className='row clickArea' style={{padding: '6px', gap: '10px'}}>
                          <span>{formattedCep}</span><a className='colorTextLink' onClick={() => setShowCustomAddress(false)}>alterar CEP</a>
                        </div>
                        <form className='column' style={{gap: '10px'}} onSubmit={handleNewAddressSubmit}>
                          <input
                            id='name'
                            type='text'
                            placeholder='"Casa" ou "Trabalho"'
                            value={newAddress.name}
                            onChange={handleNewAddressInputChange}
                            required
                          />
                          {errors.street && <span className='error'>{errors.street}</span>}
                          
                          <input
                            id='street'
                            type='text'
                            placeholder='Rua ou Avenida'
                            value={newAddress.street}
                            onChange={handleNewAddressInputChange}
                            required
                          />
                          {errors.street && <span className='error'>{errors.street}</span>}
                          
                          <div className='row' style={{gap: '10px'}}>
                            <input
                              style={{flex: '1'}}
                              id='streetNumber'
                              type='text'
                              placeholder='Número'
                              value={newAddress.streetNumber}
                              onChange={handleNewAddressInputChange}
                              required
                          />
                          {errors.streetNumber && <span className='error'>{errors.streetNumber}</span>}
                          
                          <input
                              style={{flex: '2'}}
                              id='additionalAddress'
                              type='text'
                              placeholder='Complemento (opcional)'
                              value={newAddress.additionalAddress}
                              onChange={handleNewAddressInputChange}
                            />
                          </div>
                          
                          <input
                            id='neighborhood'
                            type='text'
                            placeholder='Bairro'
                            value={newAddress.neighborhood}
                            onChange={handleNewAddressInputChange}
                            required
                          />
                          {errors.neighborhood && <span className='error'>{errors.neighborhood}</span>}
                          
                          <div className='row' style={{gap: '10px'}}>
                            <input
                              style={{flex: '4'}}
                              id='city'
                              type='text'
                              placeholder='Cidade'
                              value={newAddress.city}
                              onChange={handleNewAddressInputChange}
                              required
                            />
                            {errors.city && <span className='error'>{errors.city}</span>}
                            <input
                              style={{display: 'flex', flex: '1'}}
                              id='uf'
                              type='text'
                              placeholder='UF'
                              value={newAddress.uf}
                              onChange={(e) => {
                                const value = e.target.value.slice(0, 2).toUpperCase(); // Restrict to 2 characters and convert to uppercase
                                setNewAddress((prevState) => ({
                                  ...prevState,
                                  uf: value,
                                }));
                              }}
                              maxLength={2}
                              required
                            />
                            {errors.uf && <span className='error'>{errors.uf}</span>}
                          </div>

                          <Button
                            className='buttonColorMain smallButton'
                            label='Salvar'
                            type='submit'
                          />
                        </form>
                        <div className='row' style={{justifyContent: 'center'}}>
                          <a onClick={() => setIsAddingNewAddress(false)}>cancelar</a>
                        </div>
                      </div>
                    }
                  </div>
                </ClickOutside>
              </div>
            }

            {editingAddressOptions &&
              <div className='defaultDialog fadeIn'>
                <ClickOutside onOutsideClick={() => setEditingAddressOptions(false)}>
                  <div className='column cardFormContainer defaultDialogContainer' style={{gap: '20px'}}>
                    {/* <h2>Editar endereço</h2> */}
                    <div className='column' style={{gap: '10px'}}>
                      <span>{editAddress.name}</span>
                      <h3>{`${editAddress.street}, ${editAddress.streetNumber}, ${editAddress.city} - ${editAddress.uf}, ${editAddress.cep}`}</h3>
                    </div>
                    <div className='column fadeIn' style={{gap: '10px'}}>
                      <Button
                        className='buttonColorMain smallButton'
                        label='Editar'
                        onClick={() => {setIsEditingAddress(true); setEditingAddressOptions(false)}}
                        // iconStart={<Edit/>}
                      />
                      <Button
                        className='buttonColorSecond smallButton'
                        label='Remover'
                        // iconStart={<Close/>}
                      />
                    </div>
                    <div className='row' style={{justifyContent: 'center'}}>
                      <a onClick={() => setEditingAddressOptions(false)}>cancelar</a>
                    </div>
                  </div>
                </ClickOutside>
              </div>
            }

            {isEditingAddress &&
              <div className='defaultDialog fadeIn'>
                <ClickOutside onOutsideClick={() => setIsEditingAddress(false)}>
                  <div className='column cardFormContainer defaultDialogContainer' style={{gap: '10px'}}>
                    <h2>Editando endereço existente</h2>
                    {!showCustomAddressEdit &&
                      <div className='row fadeIn' style={{ gap: '10px' }}>
                        <input
                          id='CEP'
                          type='text'
                          placeholder='00.000-000'
                          value={formattedCep}
                          onChange={handleCepChange}
                          onKeyDown={(e) => e.key === 'Enter' && searchCep(cep)}
                        />
                        <Button
                          className='buttonColorMain smallButton'
                          // iconEnd={<CouponIcon />}
                          label='Buscar CEP'
                          onClick={() => searchCep(cep)}
                        />
                      </div>
                    }
                    {showCustomAddressEdit &&
                      <div className='column fadeIn' style={{gap: '10px'}}>
                        <div className='row clickArea' style={{padding: '6px', gap: '10px'}}>
                          <span>{formattedCep}</span><a className='colorTextLink' onClick={() => setShowCustomAddressEdit(false)}>alterar CEP</a>
                        </div>
                        <form className='column' style={{gap: '10px'}} onSubmit={handleEditAddressSubmit}>
                          <input
                            id='name'
                            type='text'
                            placeholder='"Casa" ou "Trabalho"'
                            value={editAddress.name}
                            onChange={handleEditAddressInputChange}
                            required
                          />
                          {errors.street && <span className='error'>{errors.street}</span>}
                          
                          <input
                            id='street'
                            type='text'
                            placeholder='Rua ou Avenida'
                            value={editAddress.street}
                            onChange={handleEditAddressInputChange}
                            required
                          />
                          {errors.street && <span className='error'>{errors.street}</span>}
                          
                          <div className='row' style={{gap: '10px'}}>
                            <input
                              style={{flex: '1'}}
                              id='streetNumber'
                              type='text'
                              placeholder='Número'
                              value={editAddress.streetNumber}
                              onChange={handleEditAddressInputChange}
                              required
                          />
                          {errors.streetNumber && <span className='error'>{errors.streetNumber}</span>}
                          
                          <input
                              style={{flex: '2'}}
                              id='additionalAddress'
                              type='text'
                              placeholder='Complemento (opcional)'
                              value={editAddress.additionalAddress}
                              onChange={handleEditAddressInputChange}
                            />
                          </div>
                          
                          <input
                            id='neighborhood'
                            type='text'
                            placeholder='Bairro'
                            value={editAddress.neighborhood}
                            onChange={handleEditAddressInputChange}
                            required
                          />
                          {errors.neighborhood && <span className='error'>{errors.neighborhood}</span>}
                          
                          <div className='row' style={{gap: '10px'}}>
                            <input
                              style={{flex: '4'}}
                              id='city'
                              type='text'
                              placeholder='Cidade'
                              value={editAddress.city}
                              onChange={handleEditAddressInputChange}
                              required
                            />
                            {errors.city && <span className='error'>{errors.city}</span>}
                            <input
                              style={{display: 'flex', flex: '1'}}
                              id='uf'
                              type='text'
                              placeholder='UF'
                              value={editAddress.uf}
                              onChange={(e) => {
                                const value = e.target.value.slice(0, 2).toUpperCase(); // Restrict to 2 characters and convert to uppercase
                                setEditAddress((prevState) => ({
                                  ...prevState,
                                  uf: value,
                                }));
                              }}
                              maxLength={2}
                              required
                            />
                            {errors.uf && <span className='error'>{errors.uf}</span>}
                          </div>

                          <Button
                            className='buttonColorMain smallButton'
                            label='Salvar'
                            type='submit'
                          />
                        </form>
                        <div className='row' style={{justifyContent: 'center'}}>
                          <a onClick={() => setIsEditingAddress(false)}>cancelar</a>
                        </div>
                      </div>
                    }
                  </div>
                </ClickOutside>
              </div>
            }

          <div className='column' style={{gap: '10px'}}>
            {/* <h1>Minha Conta</h1> */}

            {!isEditingUserData && !isEditingPassword &&
              <div className='card fadeIn' style={{width: '100%'}}>
                <TabSelector tabs={tabs} selectedTab={selectedTab} onTabSelect={handleTabSelect} />
              </div>
            }

            {selectedTab == 'Meus dados' &&
              <>
                {selectedUserDataView === 'personal'
                  ?
                    <div className='card fadeIn' style={{width: '100%'}}>
                      <div className='column' style={{padding: '10px', gap: '10px'}}>

                        {!isEditingUserData && !isEditingPassword &&
                          <div className='dataViewHeader'>
                            <Person className='dataViewIcon'/>
                            <h2>Dados pessoais</h2>
                          </div>
                        }
                        {isEditingUserData &&
                          <h2>Editando dados pessoais:</h2>
                        }
                        {isEditingPassword &&
                          <h2>Alterando senha:</h2>
                        }

                        {!isEditingPassword &&
                          <>
                            {!isEditingUserData
                              ?
                                <div className='rowToColumn800 fadeIn' style={{gap: '10px'}}>
                                  <div className='column fadeIn' style={{width: '100%', gap: '10px'}}>

                                    <div className='row defaultBorderedItem' style={{alignItems: 'start', gap: '10px'}}>
                                      <span className='grayedText'>Login:</span>{gpuser.email}
                                    </div>

                                    <div className='row defaultBorderedItem' style={{alignItems: 'start', gap: '10px'}}>
                                    <span className='grayedText'>Nome completo:</span>{gpuser.name}
                                    </div>

                                    <div className='row defaultBorderedItem' style={{alignItems: 'start', gap: '10px'}}>
                                      <span className='grayedText'>CPF ou CNPJ:</span>{gpuser.cpf}
                                    </div>

                                  </div>
                                      
                                  <div className='column' style={{width: '100%', gap: '10px'}}>

                                    <div className='row defaultBorderedItem' style={{alignItems: 'start', gap: '10px'}}>
                                      <span className='grayedText'>Telefone:</span>{gpuser.telefone}
                                    </div>

                                    <div className='row defaultBorderedItem' style={{alignItems: 'start', gap: '10px'}}>
                                      <span className='grayedText'>CEP:</span>{gpuser.cep}
                                    </div>

                                  </div>
                                </div>
                              :
                                <div className='rowToColumn800 fadeIn' style={{gap: '10px'}}>
                                  <div className='column fadeIn' style={{width: '100%', gap: '10px'}}>

                                    <input className='row defaultBorderedItem' placeholder={gpuser.email} style={{alignItems: 'start', gap: '10px'}}>
                                    </input>

                                    <input className='row defaultBorderedItem' placeholder='Nome completo: [user.fullName]' style={{alignItems: 'start', gap: '10px'}}>
                                    </input>

                                    <input className='row defaultBorderedItem' placeholder='CPF ou CNPJ: [user.doc]' style={{alignItems: 'start', gap: '10px'}}>
                                    </input>

                                  </div>
                                      
                                  <div className='column' style={{width: '100%', gap: '10px'}}>

                                    <input className='row defaultBorderedItem' placeholder='Telefone: [user.phone]' style={{alignItems: 'start', gap: '10px'}}>
                                    </input>

                                    <input className='row defaultBorderedItem' placeholder='CEP: [user.phone]' style={{alignItems: 'start', gap: '10px'}}>
                                    </input>

                                  </div>
                                </div>
                            }
                            {!isEditingUserData
                              ?
                                <Button
                                  className='buttonColorThird smallButton'
                                  iconStart={<Edit style={{fontSize: '16px'}}/>}
                                  label='Editar dados'
                                  onClick={() => handleDataEdit()}
                                />
                              :
                                <>
                                  <Button
                                    className='buttonColorThird smallButton'
                                    label='Salvar'
                                    onClick={() => handleDataSave()}
                                  />
                                  <a className='grayedText' style={{textAlign: 'center'}} onClick={() => handleDataCancel()}>Cancelar</a>
                                </>
                            }
                          </>
                        }
                        {!isEditingUserData &&
                          <>
                            {!isEditingPassword
                              ?
                                <div className='row fadeIn' style={{justifyContent: 'space-between', gap: '10px'}}>
                                  <div className='row clickArea defaultBorderedItem' style={{alignItems: 'start', gap: '10px', width: '100%'}}>
                                    Senha: ••••••
                                  </div>
                                  <Button
                                    className='buttonColorLight'
                                    iconStart={<Edit style={{fontSize: '16px'}}/>}
                                    label='alterar'
                                    onClick={() => handlePasswordEdit()}
                                  />
                                </div>
                              :
                                <div className='column fadeIn' style={{width: '100%', gap: '10px'}}>
                                  <input className='row defaultBorderedItem' placeholder='senha atual' style={{alignItems: 'start', gap: '10px'}}>
                                  </input>
                                  <input className='row defaultBorderedItem' placeholder='nova senha' style={{alignItems: 'start', gap: '10px'}}>
                                  </input>
                                  <input className='row defaultBorderedItem' placeholder='confirmar nova senha' style={{alignItems: 'start', gap: '10px'}}>
                                  </input>
                                  <Button
                                    className='buttonColorThird smallButton'
                                    label='Salvar nova senha'
                                    onClick={() => handlePasswordSave()}
                                  />
                                  <a className='grayedText' style={{textAlign: 'center'}} onClick={() => handlePasswordCancel()}>Cancelar</a>
                                </div>
                            }
                          </>
                        }
                        {!isEditingUserData && !isEditingPassword &&
                          <Button
                            className='buttonColorMain smallButton'
                            iconStart={<Logout style={{fontSize: '16px'}}/>}
                            label='Sair da conta'
                            onClick={logout}
                          />
                        }
                      </div>
                    </div>
                  :
                    <div className='card clickArea fadeIn' style={{width: '100%'}} onClick={() => handleUserDataViewSelect('personal')}>
                      <div className='row' style={{padding: '10px', justifyContent: 'space-between', gap: '10px'}}>
                        <div className='dataViewHeader'>
                          <Person className='dataViewIcon'/>
                          <h2>Dados pessoais</h2>
                        </div>
                        <div className='row center'>
                          <ExpandDownIcon style={{color: 'gray'}}/>
                          {/* <span>mais</span> */}
                        </div>
                      </div>
                    </div>
                }
                {!isEditingUserData && !isEditingPassword &&
                  <>
                    {selectedUserDataView === 'addresses'
                      ?
                        <div className='card fadeIn' id='enderecos' style={{width: '100%', gap: '20px'}}>
                          <div className='column' style={{padding: '10px', gap: '10px'}}>
                            <div className='dataViewHeader'>
                              <HomeWork className='dataViewIcon'/>
                              <h2>Meus endereços</h2>
                            </div>
                            <div className='column fadeIn' style={{gap: '10px'}}>
                              {/* {UserAddresses.map((address, index) => (
                                <div className='row' style={{justifyContent: 'space-between', gap: '10px'}}>
                                  <div className='row clickArea defaultBorderedItem' style={{alignItems: 'start', gap: '6px', width: '100%', whiteSpace: 'nowrap', overflowX: 'hidden'}}>
                                    <span>{UserAddresses[index].name}</span>
                                    <span className='grayedText'>{UserAddresses[index].street}, {UserAddresses[index].streetNumber} - {UserAddresses[index].neighborhood}, {UserAddresses[index].city}</span>
                                  </div>
                                  <Button
                                    className='buttonColorLight'
                                    iconStart={<Edit style={{fontSize: '16px'}}/>}
                                    label='editar'
                                    onClick={() => editAddress(index)}
                                  />
                                </div>
                              ))} */}
                              {gpuser.address.map((address, index) => (
                                <AddressTile
                                  key={index} // Ensure each element has a unique key prop
                                  name={address.name}
                                  address={`${address.street}, ${address.number} - ${address.district}, ${address.city} - ${address.state}, ${address.zipCode}`}
                                  isSelected={false}
                                  isDefault={address.isDefault}
                                  onClick={() => existingAddressOptions(index)}
                                />
                              ))}
                              <Button
                                className='buttonColorSecond smallButton'
                                iconStart={<Add style={{fontSize: '16px'}}/>}
                                label='Adicionar endereço'
                                onClick={() => handleAddNewAddress()}
                              />
                            </div>
                          </div>
                        </div>
                      :
                        <div className='card clickArea fadeIn' style={{width: '100%'}} onClick={() => handleUserDataViewSelect('addresses')}>
                          <div className='row' style={{padding: '10px', justifyContent: 'space-between', gap: '10px'}}>
                            <div className='dataViewHeader'>
                              <HomeWork className='dataViewIcon'/>
                              <h2>Meus endereços</h2>
                            </div>
                            <div className='row center'>
                              <ExpandDownIcon style={{color: 'gray'}}/>
                              {/* <span>mais</span> */}
                            </div>
                          </div>
                        </div>
                    }
                    
                    {/* {selectedUserDataView === 'creditCards'
                      ?
                        <div className='card fadeIn' id='cartoes' style={{width: '100%', gap: '20px'}}>
                          {!addingNewCard
                            ?
                              <div className='column fadeIn' style={{padding: '10px', gap: '10px'}}>
                                <div className='dataViewHeader'>
                                  <CreditCard className='dataViewIcon'/>
                                  <h2>Meus cartões de crédito</h2>
                                </div>
                                <div className='column' style={{gap: '10px'}}>
                                  {userCreditCards.map((card, index) => (
                                    <CardTile
                                    key={index}
                                    cardNumber={card.cardNumber}
                                    isDefault={card.isDefault}
                                    isSelected={false}
                                    onClick={() => {}}
                                    />
                                  ))}
                                  <Button
                                    className='buttonColorSecond smallButton'
                                    iconStart={<Add style={{fontSize: '16px'}}/>}
                                    label='Cartão de crédito'
                                    onClick={() => handleAddNewCard(true)}
                                  />
                                </div>
                              </div>
                          :
                            <div className='column' style={{padding: '10px', gap: '10px'}}>
                              <div className='row' style={{alignItems: 'center'}}>
                                <a className='grayedText' onClick={() => handleAddNewCard(false)}><ArrowBackSharp style={{fontSize: '18px', marginRight: '4px'}}/></a>
                                <h2>Novo Cartão de Crédito</h2>
                              </div>  
                              <form id='newCard' className='cardRowContainer'>
                                <div className='column' style={{gap: '10px', flex: '1'}}>
                                  <div className='cardFormContainer'>
                                    <div className='cardShape cardColor'>
                                      <div className='row' style={{gap: '10px'}}>
                                        <div className='column' style={{flex: '3', gap: '10px'}}>
                                          <div className='column'>
                                            <span>Número:</span>
                                            <input
                                              id='cardNumber'
                                              type='text'
                                              placeholder='0000 0000 0000 0000'
                                              value={cardNumber}
                                              onChange={handleCardNumberChange}
                                              onBlur={handleCardNumberChange}
                                              style={{ borderColor: isCardNumberValid === false && 'red' }}
                                              />
                                          </div>

                                          <div className='column'>
                                            <span>Nome do titular:</span>
                                            <input
                                              id='cardHolderName'
                                              type='text'
                                              placeholder='Conforme escrito no cartão'
                                              value={cardHolderName}
                                              onChange={handleCardHolderNameChange}
                                              onBlur={handleCardHolderNameChange}
                                              style={{ borderColor: isCardHolderNameValid === false && 'red' }}
                                            />
                                          </div>
                                        </div>
                                        <div className='antifraudContainer'>
                                          <img src={antifraudIcon}/>
                                          <div className='column' style={{gap: '2px'}}>
                                            <h3>PAGAMENTO<br/>SEGURO</h3>
                                            <span>com sistema<br/>antifraude</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className='row' style={{gap: '10px'}}>
                                        <div className='column'>
                                          <span>Validade:</span>
                                          <input
                                            id='validThru'
                                            type='text'
                                            placeholder='00/00'
                                            value={validThru}
                                            onChange={handleValidThruChange}
                                            onBlur={handleValidThruChange}
                                            style={{ borderColor: isValidThruValid === false && 'red' }}
                                          />
                                        </div>
                                        <div className='column'>
                                          <span>Código de Verificação:</span>
                                          <input
                                            id='verificationCode'
                                            type='text'
                                            placeholder='3 ou 4 dígitos'
                                            value={verificationCode}
                                            onChange={handleVerificationCodeChange}
                                            onBlur={handleVerificationCodeChange}
                                            style={{ borderColor: isVerificationCodeValid === false && 'red' }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='column' style={{flex: '1', gap: '10px'}}>
                                  <div className='cardShape'>
                                    <div className='column'>
                                      <span>CPF ou CNPJ do titular do cartão</span>
                                      <input
                                        id='clientDocCredit'
                                        type='text'
                                        placeholder='obrigatório'
                                        value={clientDoc}
                                        onChange={handleClientDocChange}
                                        onBlur={handleClientDocChange}
                                        style={{ borderColor: isClientDocValid === false && 'red' }}
                                      />
                                    </div>
                                    <div className='column'>
                                      <span>Telefone do titular do cartão:</span>
                                      <input
                                        id='phoneCredit'
                                        type='text'
                                        placeholder='obrigatório'
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        onBlur={handlePhoneChange}
                                        style={{ borderColor: isPhoneValid === false && 'red' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </form>

                              <div className='column' style={{gap: '10px'}}>
                                <h3>Endereço de cobrança</h3>
                                <AddressTile
                                  id='0'
                                  name='É o mesmo da entrega'
                                  address={selectedAddress.street + ', ' + selectedAddress.streetNumber + ' - ' + selectedAddress.neighborhood + ', ' + selectedAddress.city + ' - ' + selectedAddress.uf + ', ' + selectedAddress.cep}
                                  isSelected={!newPayAddressSelected}
                                  onClick={() => setNewPayAddressSelected(false)}
                                />
                                {!newPayAddressSelected
                                  ?
                                    <div className='column defaultBorderedItem clickArea cardFormContainer itemSelect' style={{gap: '10px'}} onClick={() => addNewPayAddress()}>
                                      <span>Endereço de cobrança é outro</span>
                                    </div>
                                  :
                                    <AddressTile
                                      name='Endereço de cobrança é outro'
                                      address={newPayAddress.street + ', ' + newPayAddress.streetNumber + ' - ' + newPayAddress.neighborhood + ', ' + newPayAddress.city + ' - ' + newPayAddress.uf + ', ' + newPayAddress.cep}
                                      isSelected={newPayAddressSelected}
                                      onClick={() => addNewPayAddress()}
                                    />
                                }
                                {isAddingPayAddress &&
                                  <div className='defaultDialog fadeIn'>
                                    <ClickOutside onOutsideClick={() => setIsAddingPayAddress(false)}>
                                      <div className='column cardFormContainer defaultDialogContainer' style={{gap: '10px'}}>
                                        <h2>Outro endereço de cobrança</h2>
                                        {!showCustomAddress &&
                                          <div className='row fadeIn' style={{ gap: '10px' }}>
                                            <input
                                              id='CEP'
                                              type='text'
                                              placeholder='00.000-000'
                                              value={formattedCep}
                                              onChange={handleCepChange}
                                              onKeyDown={(e) => e.key === 'Enter' && searchCep(cep)}
                                            />
                                            <Button
                                              className='buttonColorMain smallButton'
                                              // iconEnd={<CouponIcon />}
                                              label='Buscar CEP'
                                              onClick={() => searchCep(cep)}
                                            />
                                          </div>
                                        }
                                        {showCustomAddress &&
                                          <div className='column fadeIn' style={{gap: '10px'}}>
                                            <div className='row clickArea' style={{padding: '6px', gap: '10px'}}>
                                              <span>{formattedCep}</span><a className='colorTextLink' onClick={() => setShowCustomAddress(false)}>alterar CEP</a>
                                            </div>
                                            <form className='column' style={{gap: '10px'}} onSubmit={handleNewPayAddressSubmit}>
                                              <input
                                                id='street'
                                                type='text'
                                                placeholder='Rua ou Avenida'
                                                value={newPayAddress.street}
                                                onChange={handleNewPayAddressInputChange}
                                                required
                                              />
                                              {errors.street && <span className='error'>{errors.street}</span>}
                                              
                                              <div className='row' style={{gap: '10px'}}>
                                                <input
                                                  style={{flex: '1'}}
                                                  id='streetNumber'
                                                  type='text'
                                                  placeholder='Número'
                                                  value={newPayAddress.streetNumber}
                                                  onChange={handleNewPayAddressInputChange}
                                                  required
                                                />
                                                {errors.streetNumber && <span className='error'>{errors.streetNumber}</span>}
                                                
                                                <input
                                                  style={{flex: '2'}}
                                                  id='additionalAddress'
                                                  type='text'
                                                  placeholder='Complemento (opcional)'
                                                  value={newPayAddress.additionalAddress}
                                                  onChange={handleNewPayAddressInputChange}
                                                />
                                              </div>
                                              
                                              <input
                                                id='neighborhood'
                                                type='text'
                                                placeholder='Bairro'
                                                value={newPayAddress.neighborhood}
                                                onChange={handleNewPayAddressInputChange}
                                                required
                                              />
                                              {errors.neighborhood && <span className='error'>{errors.neighborhood}</span>}
                                              
                                              <div className='row' style={{gap: '10px'}}>
                                                <input
                                                  style={{flex: '4'}}
                                                  id='city'
                                                  type='text'
                                                  placeholder='Cidade'
                                                  value={newPayAddress.city}
                                                  onChange={handleNewPayAddressInputChange}
                                                  required
                                                />
                                                {errors.city && <span className='error'>{errors.city}</span>}
                                                <input
                                                  style={{display: 'flex', flex: '1'}}
                                                  id='uf'
                                                  type='text'
                                                  placeholder='UF'
                                                  value={newPayAddress.uf}
                                                  onChange={(e) => {
                                                    const value = e.target.value.slice(0, 2).toUpperCase(); // Restrict to 2 characters and convert to uppercase
                                                    setNewPayAddress((prevState) => ({
                                                      ...prevState,
                                                      uf: value,
                                                    }));
                                                  }}
                                                  maxLength={2}
                                                  required
                                                />
                                                {errors.uf && <span className='error'>{errors.uf}</span>}
                                              </div>

                                              <Button
                                                className='buttonColorMain smallButton'
                                                label='Salvar'
                                                type='submit'
                                              />
                                            </form>
                                            <div className='row' style={{justifyContent: 'center'}}>
                                              <a onClick={() => setIsAddingPayAddress(false)}>cancelar</a>
                                            </div>
                                          </div>
                                        }
                                      </div>
                                    </ClickOutside>
                                  </div>
                                }
                              </div>
                              <Button
                                className='buttonColorMain smallButton'
                                label='Concluir'
                                onClick={() => saveNewCard()}
                              />
                              <div className='row' style={{justifyContent: 'center'}}>
                                <a onClick={() => handleAddNewCard(false)}>cancelar</a>
                              </div>
                            </div>
                          }
                        </div>
                      :
                        <div className='card clickArea fadeIn' style={{width: '100%'}} onClick={() => handleUserDataViewSelect('creditCards')}>
                          <div className='row' style={{padding: '10px', justifyContent: 'space-between', gap: '10px'}}>
                            <div className='dataViewHeader'>
                              <CreditCard className='dataViewIcon'/>
                              <h2>Meus cartões de crédito</h2>
                            </div>
                            <div className='row center'>
                              <ExpandDownIcon style={{color: 'gray'}}/>
                              <span>mais</span>
                            </div>
                          </div>
                        </div>
                    } */}

                  </>
                }
              </>
            }

            {selectedTab == 'Serviços' &&   
              <div className='card fadeIn' style={{width: '100%', gap: '20px'}}>
                <div className='column' style={{padding: '10px', gap: '10px'}}>
                  {selectedVersion
                    ?
                      <>
                        <h2>Serviços para:</h2>
                        <div className='row defaultBorderedItem clickArea' style={{gap: '10px', flexWrap: 'wrap'}} onClick={openVehicleSelect}>
                            <a style={{color: 'var(--mainColor)'}} >{selectedModel} {selectedYear} {selectedVersion} <Edit style={{fontSize: '16px', marginLeft: '6px'}}/> alterar</a>
                        </div>
                        <div className='column' style={{gap: '10px'}}>
                          <h2 style={{textAlign: 'center'}}>Nenhum serviço disponível para este veículo</h2>
                          {/* {DealerServices.map((service, index) => (
                            <div key={index} className="column defaultBorderedItem clickArea">
                              <h3>{service.dealer}</h3>
                              <span>Serviço: {service.service}</span>
                              <span>Cidade: {service.location}</span>
                              <span>a partir de: {formatPrice(service.startingPrice)}</span>
                            </div>
                          ))} */}
                        </div>
                      </>
                    :
                      <>
                        <h2 style={{textAlign: 'center'}}>Selecione um veículo para ver os serviços disponíveis</h2>
                        <div className='row' style={{gap: '10px', justifyContent: 'center'}}>
                          <Button
                            className='buttonColorThird smallButton'
                            label='Selecionar veículo'
                            onClick={openVehicleSelect}
                          />
                        </div>
                      </>
                  }
                  
                </div>
              </div>
            }

            {selectedTab == 'Pedidos' &&
              <PedidosComponent/>
            }

          </div>
        </div>
      }
      {/* Additional account information and functionality here */}
    </div>
  );
}

export default MyAccount;