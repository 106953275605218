import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import '../App.css';
import './ProductPage.css';
import { setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion } from '../reducers/vehicle';
import ProductImgSwiper from '../Common/Sliders/ProductImgSwiper';
import Button from '../Common/Button';
import SellerTile from '../Common/Tiles/SellerTile';
import { SellerPH, CardOperators, storeInfo, ShippingOptions, productReview } from '../Utilities/Placeholders';
import ExpandDownIcon from "@mui/icons-material/ArrowDropDown";
import ShippingIcon from "@mui/icons-material/LocalShippingOutlined";
import SearchForIcon from "@mui/icons-material/Search";
import ExpandUpIcon from "@mui/icons-material/ArrowDropUp";
import ShareIcon from "@mui/icons-material/Share";
import { formatPrice, formatPercent } from '../Utilities/Formats';
import GoParca from '../images/artwork/goparca_01_300_01.png';
import WhatsappIcon from "@mui/icons-material/WhatsApp";
import { Add, Share, ShareRounded, Sort, SwapVert, Home, Star, StarHalf, StarOutline, Straighten, ReportOutlined, } from '@mui/icons-material';
import Ssl from '../images/ssl_selo.webp';
import Lgpd from '../images/LGPD_small.webp';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TO_CART } from '../reducers/cart';
import Product from '../Common/Classes/Product';
import { BrandsList } from '../Utilities/BrandsList';
import defaultImageJpg from '../images/defaultImage.jpg';
import antifraudIcon from '../images/Icone_Antifraude.png';
import GoParca_Show_01 from '../images/artwork/goparca_01_300_01_nardo.webp';
import GoParca_Zen from '../images/artwork/goparca_03_full_mirror_zen_nardo.webp';
import GoParca_Zen_2 from '../images/artwork/goparca_03_full_zen_nardo.webp';
import { handleWhatsappClick } from '../Utilities/Whatsapp';
import { firestore } from '../firebase' // Adjust the import according to your Firebase configuration
import { doc, getDoc, collection } from 'firebase/firestore'; // Import Firestore functions you need
import { CircularProgress } from '@mui/material'; // Material-UI spinner, you can use any spinner or custom loader
import { contentData, configData } from '../Whitelabel/websitesData';
import { getFirestore, query, where, getDocs } from "firebase/firestore";
import { getAddress } from '../Utilities/logisticservices';
import UHD_productPage_bg_01 from '../images/UmuaramaHD/UHD_productPage_bg_01.webp';
import { functions } from '../firebase'; // Ensure the correct path to your firebase.js
import { httpsCallable } from 'firebase/functions';

function ProductPage() {
    const [ triggerGtmViewItem, setTriggerGtmViewItem ] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const destinationRef = useRef(null);
    const [highlightSeller, setHighlightSeller] = useState(false);
    const [highlightShipping, setHighlightShipping] = useState(false);

    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);
    const vehicleState = useSelector(state => state.vehicle);

    const [cep, setCep] = useState('');
    const [hasShipping, setHasShipping] = useState(false);

    const [isExpandedInfo, setIsExpandedInfo] = useState(false);
    const [isSellersListExpanded, setIsSellersListExpanded ] = useState(false);
    const toggleExpandInfo = () => {
        setIsExpandedInfo(!isExpandedInfo);
    };

    const [sortSellers, setSortSellers] = useState('lowestPrice')
    const [productProps, setProductProps] = useState(null); // Initialize state for productProps
    const [product, setProduct] = useState(null); // State for the Product object
    const [sellers, setSellers] = useState([]); // State for the sellers list
    const [vehicleNote, setVehicleNote] = useState('');
    const [sortedSellers, setSortedSellers] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [loadingShipping, setLoadingShipping ] = useState(false);

    const [multiShipProgress, setMultiShipProgress] = useState(''); // State to track progress
    const [loadingMultiShip, setLoadingMultiShip ] = useState(false);
    const [multiShipCEP, setMultiShipCEP] = useState('');

    const [ sellerBrandName, setSellerBrandName ] = useState('');

    const [hasSale, setHasSale] = useState(false);

    const getShippingQuotes = httpsCallable(functions, 'getShippingQuotes');

    const toTitleCase = (str) => {
        if (typeof str !== 'string') {
            return ''; // Return an empty string or handle as needed
        }
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    const [ brandName, setBrandName ] = useState(configData.defaultStore === 'UmuaramaHD' ? 'Harley Davidson' : selectedBrand ? toTitleCase(selectedBrand) : 'Genuína');
    const [productPrefix, setProductPrefix ] = useState('');

    const [ isClothing, setIsClothing ] = useState(false); // Implement: is current product clothing?

    const [ selectedSize, setSelectedSize ] = useState(''); 
    const [ selectedColor, setSelectedColor ] = useState(''); 

    const topMargin = configData.defaultStore === 'UmuaramaHD' ? '100px' : '20px';
    const productPageBg = configData.defaultStore === 'UmuaramaHD' ? UHD_productPage_bg_01 : '';

    const Weight = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M240-200h480l-57-400H297l-57 400Zm240-480q17 0 28.5-11.5T520-720q0-17-11.5-28.5T480-760q-17 0-28.5 11.5T440-720q0 17 11.5 28.5T480-680Zm113 0h70q30 0 52 20t27 49l57 400q5 36-18.5 63.5T720-120H240q-37 0-60.5-27.5T161-211l57-400q5-29 27-49t52-20h70q-3-10-5-19.5t-2-20.5q0-50 35-85t85-35q50 0 85 35t35 85q0 11-2 20.5t-5 19.5ZM240-200h480-480Z"/></svg>
        )
    }

    const Certified = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z"/></svg>
        )
    }

    const NewProduct = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z"/></svg>
        )
    }

    const Barcode = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M40-200v-560h80v560H40Zm120 0v-560h80v560h-80Zm120 0v-560h40v560h-40Zm120 0v-560h80v560h-80Zm120 0v-560h120v560H520Zm160 0v-560h40v560h-40Zm120 0v-560h120v560H800Z"/></svg>
        )
    }
    
    const fetchProductFromFirestore = async () => {
        try {
            let productId = location.pathname.slice(1);
            //const productDoc2 = await firestore.collection('gp_warehouse').doc("GENUINAVWAUDI_5WA407366").get();

           
            const warehouseRef = collection(firestore, 'gp_warehouse'); // Reference to the collection

            // Firestore query with whereIn clause
            const q = query(warehouseRef, where('ds_number_gp', '==', productId));
            
            const querySnapshot = await getDocs(q); // Get the query snapshot
            
            // Map over the documents and return an array of items
            const itemsArray = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
            }));

            // const productDocRef = doc(firestore, 'gp_warehouse', "GENUINAHARLEYDAVIDSON_9806619VW000M");
            // const productDoc = itemsArray.firs
            if (itemsArray.length > 0) {
                const myProduct  = itemsArray[0];
                setProductProps(myProduct);

                const listOfSellers = myProduct.sellersList;
                const growableList = [];
                const storeprice  = configData.defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";

                listOfSellers.forEach((element) => {
                    const sellerData = myProduct[element];
                    
                    if (configData.storeSellersWarehouses.includes(element)) {
                        growableList.push({
                            id: myProduct.id.toString(),
                            active: myProduct.active,
                            name: element,
                            price: sellerData.price,
                            stock: sellerData.stock,
                            price_ecommerce: configData.defaultStore === "GoParts"
                                ? (sellerData.price_ecommerce_gp || sellerData.price)
                                : (sellerData.price_ecommerce_seller || sellerData.price),
                            distance: 1
                        });
                    }
                });

                growableList.sort((a, b) => {
                    var mina = a.price_ecommerce < a.price ? a.price_ecommerce : a.price;
                    var minb = b.price_ecommerce < b.price ? b.price_ecommerce : b.price;

                    if (mina < minb) return -1;

                    if (minb < mina) return 1;
                    return 0;
                });
                //return new Product(productDoc)
                const newProduct = new Product(
                    myProduct.active,
                    myProduct.desc,
                    myProduct.ds_number,
                    myProduct.id_supplier,
                    myProduct.imhash,
                    growableList,
                    {
                        height: myProduct.altCM,
                        width: myProduct.largCM,
                        depth: myProduct.profCM,
                        weight: myProduct.pesoR
                    },
                    myProduct.prop_car
                );

                // Set the new Product instance in the state
                setProduct(newProduct);
                setProductPrefix(newProduct.supplier);

                // Get sellers from the product and set in the state
                const sellersList = newProduct.getSellers();
                setSellers(sellersList);

                const vehicleNoteText = newProduct.getNotes(InternalID); // Make sure InternalID is correctly defined
                setVehicleNote(vehicleNoteText);

                setSortedSellers(sellersList);

                setSelectedSeller(sellersList[0]);


            } else {
                console.error('No product found in Firestore with the given ID.');
            }
        } catch (error) {
            console.error('Error fetching product from Firestore:', error);
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };
    
    useEffect(() => {
        if (location.state?.product) {
            try {
                setProductProps(location.state?.product);
    
                const currentProduct = new Product(
                    location.state?.product.active,
                    location.state?.product.description,
                    location.state?.product.ds_number,
                    location.state?.product.supplier,
                    location.state?.product.images,
                    [], // sellers will be filtered and set later
                    {
                        height: location.state?.product.measures.height,
                        width: location.state?.product.measures.width,
                        depth: location.state?.product.measures.depth,
                        weight: location.state?.product.measures.weight
                    },
                    location.state?.product.listofPropertiesbyCar
                );
    
                // Filter the sellers based on configData.storeSellersWarehouses
                const filteredSellers = location.state?.product.sellers.filter(seller =>
                    configData.storeSellersWarehouses.includes(seller.name) && seller.stock > 0
                ).map(seller => {                    
                    return {
                        ...seller,
                        // Keep existing price_ecommerce if it exists, otherwise try the store-specific logic
                        price_ecommerce: seller.price_ecommerce || (
                            configData.defaultStore === "GoParts"
                                ? (seller.price_ecommerce_gp || seller.price)
                                : (seller.price_ecommerce_seller || seller.price)
                        )
                    };
                });
    
                // Sort filtered sellers by price
                filteredSellers.sort((a, b) => {
                    const priceA = a.price_ecommerce < a.price ? a.price_ecommerce : a.price;
                    const priceB = b.price_ecommerce < b.price ? b.price_ecommerce : b.price;
                    return priceA - priceB;
                });
    
                // Set the filtered sellers in the Product instance and state
                currentProduct.sellers = filteredSellers;
                setProduct(currentProduct);
    
                // Set the filtered sellers in state
                setSellers(filteredSellers);
    
                const vehicleNoteText = currentProduct.getNotes(InternalID); // Make sure InternalID is correctly defined
                setVehicleNote(vehicleNoteText);
    
                setSortedSellers(filteredSellers);
    
                setSelectedSeller(filteredSellers[0]);
    
                setProductPrefix(currentProduct.supplier);
        
            } catch (error) {
                console.error('Error processing product from location.state:', error);
            } finally {
                setLoading(false); // Set loading to false after processing
            }
        } else {
            fetchProductFromFirestore(); // Fetch product data when the component mounts
        }
    }, []);
    

    const defaultImage = location.state?.defaultImage ?? defaultImageJpg;
    
    const calculateMaxParcels = (price) => {
        const { maxParcels, checkoutParcels } = configData.payment;
    
        // Determine the applicable number of parcels based on the checkoutParcels rules
        let applicableParcels = 1;
        for (const [amount, parcels] of Object.entries(checkoutParcels)) {
            if (price >= parseFloat(amount)) {
                applicableParcels = parcels;
            } else {
                break;
            }
        }
    
        // Use the minimum between applicable parcels and the maximum allowed by config
        const finalParcels = Math.min(applicableParcels, maxParcels);
        const parcelPrice = price / finalParcels;
    
        // Return the string with the number of parcels and the formatted price
        return `${finalParcels}x de ${formatPrice(parcelPrice)}`;
    };

    let measures = {}
    ;

    useEffect(() => {
        if (selectedSeller) {
            setHasSale(selectedSeller.price_ecommerce < selectedSeller.price);
        } else {
            setHasSale(false);
        }
    }, [selectedSeller]);
    
    const hasSellers = sellers.length > 0;

    const [shippingListbySeller, setShippingListbySeller] = useState([]);

    const whatsappInfo = 'De segunda à sexta, das 8h às 18h.'

    const handleSellerClick = (seller) => {
        setSelectedSeller(seller);
        scrollToRef('selectedSeller', 200);
        setHighlightSeller(true);

        if (!shippingListbySeller.hasOwnProperty(seller.name)) {
            setHasShipping(false);
        } else {
            setHasShipping(true);
        }
        setTimeout(() => {
          setHighlightSeller(false);
        }, 1400);
    };

    useEffect(() => {
        if (selectedSeller && !shippingListbySeller.hasOwnProperty(selectedSeller.name)) {
            handleCEPSearch(cep);
        }
    }, [shippingListbySeller, selectedSeller, cep]);

    const scrollToRef = (ref, offset) => {
        const element = document.getElementById(ref);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          window.scrollTo({
            top: elementPosition + window.pageYOffset - offset,
            behavior: 'smooth',
          });
        }
    }

    const handleCEPSubmit = async (event) => {
        event.preventDefault(); // Prevent page refresh
        setMultiShipCEP('');
        setShippingListbySeller('');
        const cep = event.target.elements["cep"].value; // Adjust this if you're getting `cep` from the form input
        if (cep.length == 9) {
            setLoadingShipping(true);
            await calculateShipping(cep, selectedSeller); // Call your shipping function
        }
    };
    
    const handleCEPSearch = async (cep) => {
        if (cep.length == 9) {
            setLoadingShipping(true);
            await calculateShipping(cep, selectedSeller); // Call your shipping function
        }
    };

    const transformShippingOptions = (options) => {
        return options
          .filter(option => !option.errormessage)  // Exclude entries with errors
          .map(option => {
            const key = Object.keys(option)[0];  // Get the dynamic key (e.g., 'jadlog', 'correios')
            const value = option[key];           // Get the corresponding value (object with total/prazo)
      
            // Create the transformed object
            return {
              name: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the key for name
              deliveryPrice: value.total !== undefined
                ? value.total.toFixed(2) // Ensure deliveryPrice is a string with two decimal places
                : value.valor.replace(",", "."), // Convert string 'valor' with a comma to a number
              deliveryTime: value.prazo.toString(), // Convert prazo to string
            };
          });
      };
      
    // Function to extract the total dynamically
    const getTotal = (option) => {
        const key = Object.keys(option)[0]; // Get the dynamic key
        const value = option[key];
    
        if (value.total !== undefined) return value.total;           // If `total` exists, return it
        if (value.valor !== undefined) return parseFloat(value.valor.replace(",", ".")); // Convert `valor` to a number
        return null;
    };

    const handleCalculateShippingForAll = async () => {
        if (cep.length === 9) { // Check if cep is valid
            setLoadingShipping(true); // Start loading indicator
            setLoadingMultiShip(true);
    
            const totalSellers = sortedSellers.length; // Get the total number of sellers
            let currentSeller = 0;
    
            // Iterate through each seller in sortedSellers and calculate shipping
            for (const seller of sortedSellers) {
                currentSeller += 1;
    
                // Update progress (e.g., "1/10", "2/10")
                setMultiShipProgress(`${currentSeller-1}/${totalSellers}`);
    
                if (!shippingListbySeller.hasOwnProperty(seller.name)) {
                    await calculateShipping(cep, seller); // Pass both the cep and seller to the function
                }

            }
    
            setLoadingShipping(false); // Stop loading indicator once done
            setMultiShipProgress(""); // Reset progress when done
            setLoadingMultiShip(false);
            setMultiShipCEP(cep);
        } else {
            alert("Favor inserir um CEP válido"); // Ensure user provides a valid CEP
        }
    };

    useEffect(() => {
        //console.log('multiShipCEP: ', multiShipCEP);
    }, [multiShipCEP]);

    const calculateShipping = async (cep, seller) => {
        // Filter out the shipping option with the name 'Retirar no local'
        let filteredShippingOptions = ShippingOptions.filter(option => option.name !== 'Retirar no local');
        try {
            const dealersQuery = query(
            collection(getFirestore(), 'dealers'),
            where('nome', '==', seller.name)
            );

            const snapProducts = await getDocs(dealersQuery);
            
            if (snapProducts.empty) {
                throw new Error('No dealer found with the specified name');
            }
        
            const doc = snapProducts.docs[0];  // Get the first matching document
        
            const latStore = doc.get('latitude');
            const longStore = doc.get('longitude');
        
            let shippingQts = {};
            shippingQts['seller'] = seller.name; 
        
            // Clean up the 'cep' field to remove any hyphens
            let cepAux = doc.get('cep');
            let cepOrigem = cepAux.replace(/-/g, '');

            let addressAux;
            let cnpjDestinatario;
            
            cep = cep.replace(/[.-]/g, '') || '';
            cnpjDestinatario = "39456281000127"
        

            let freightoptions = [];
            const requestData = {
                cepOrigem: cepOrigem,
                cepDestino: cep,
                frap: 0,
                peso: String(product.measures.weight/1000),
                comprimento: String(product.measures.depth),
                largura: String(product.measures.width),
                altura: String(product.measures.height),
                modalidade: 3,
                tpentrega: "D",
                tpseguro: "N",
                vldeclarado: String(seller.price),
                volumes: 1,
                vlrMercadoria: String(seller.price),
                cnpjRemetente: "39456281000127",
                cnpjDestinatario:  cnpjDestinatario
            };
            try {
                const result = await getShippingQuotes(requestData);
                const validTotals = result.data
                    .filter(option => !option.errormessage)  // Remove items with errors
                    .map(getTotal)                           // Extract totals
                    .filter(total => total !== null);        // Remove any null values

                    // Get the lowest total
                const lowestTotal = Math.min(...validTotals);
                filteredShippingOptions = transformShippingOptions(result.data);
                
                const newShippingItem = {
                    clientCEP: cep,
                    item: product, // Ensure you have 'product' in the scope of this function
                    seller: seller, // Ensure you have 'seller' in the scope of this function
                    shippingOptions: filteredShippingOptions
                };
            
                // Update shippingListbySeller state
                setShippingListbySeller(prevList => {
                    const updatedList = { ...prevList };
                    updatedList[seller.name] = newShippingItem;
                    return updatedList;
                });

                //console.log(lowestTotal); // 22.44
                freightoptions.push(result);
                //console.log(result);
            } catch (err) {
                console.error('Error calling getShippingQuotes:', err);
            }
            
            //element.shippingop = transformShippingData(freightoptions);
        } catch (error) {
        console.error('Error fetching shipping quote:', error);
        throw error;
        } finally {
            // Set loading to false only when everything is done
            //setLoadingShippingOptions(false);
            //console.log('Loading shipping completed');
        }
    
        // Create the new shipping information item
        const newShippingItem = {
            clientCEP: cep,
            item: product, // Ensure you have 'product' in the scope of this function
            seller: seller, // Ensure you have 'seller' in the scope of this function
            shippingOptions: filteredShippingOptions
        };
    
        // Update shippingListbySeller state
        setShippingListbySeller(prevList => {
            const updatedList = { ...prevList };
            updatedList[seller.name] = newShippingItem;
            return updatedList;
        });

        updateSorting();
    
        setHasShipping(true); // Assuming this function is required based on your logic
        setLoadingShipping(false);
        if (seller === selectedSeller) {
            setHighlightShipping(true);
            if (product && product.getSellers().length > 1) {
            scrollToRef('calculatedShipping', 200);
            }
            setTimeout(() => {
                setHighlightShipping(false);
              }, 1400);
        }

    };

    useEffect(() => {
        //console.log('shippingListbySeller: ', shippingListbySeller);
    }, [shippingListbySeller]);

    const resetShipping = () => {
        setHasShipping(false);
        // setCep('');
    }

    const handleCEP = (e) => {
        let value = e.target.value;
    
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');
    
        // Format as XXXXX-XXX
        if (value.length > 5) {
          value = value.replace(/(\d{5})(\d{1,3})/, '$1-$2');
        }
    
        // Limit to 9 characters (8 digits + 1 dash)
        if (value.length > 9) {
          value = value.substring(0, 9);
        }
    
        setCep(value);
    };

    const lowestPriceSeller = SellerPH.reduce((lowest, seller) => {
        return (lowest === null || seller.price < lowest.price) ? seller : lowest;
    }, null);

    const ShareButton = () => {
        const handleShare = async () => {
            try {
                // Check if Clipboard API is available
                if (navigator.clipboard && navigator.clipboard.writeText) {
                    await navigator.clipboard.writeText(window.location.href);
                    // alert('Link copiado!');
                } else {
                    // Fallback for older browsers or if Clipboard API is not available
                    const textArea = document.createElement("textarea");
                    textArea.value = window.location.href;
                    document.body.appendChild(textArea);
                    textArea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textArea);
                    // alert('Link copiado!');
                }
            } catch (error) {
                console.error('Erro ao copiar link:', error);
                // alert('Falha ao copiar link :(');
            }
        };
    
        return (
            <Button
                className='buttonStyle buttonColorLight smallButton'
                label='copiar link'
                iconStart={<ShareRounded style={{fontSize: '16px'}}/>}
                onClick={handleShare}
            />
        );
    };

    const handleExpandSellers = () => {
        setIsSellersListExpanded(true);
    };

    const handleShrinkSellers = () => {
        const element = document.getElementById('sellersList');
        setIsSellersListExpanded(false);
        if (element) {
            //console.log('has element for scroll');
            const elementPosition = element.getBoundingClientRect().top;
            window.scrollTo({
                top: elementPosition + window.pageYOffset - 80,
                behavior: 'smooth',
          });
        };
    };

    const InternalID = vehicleState.selectedVehicle.InternalID;

    const brandKeywords = {
        audi: 'Audi',
        bmw: 'BMW',
        chery: 'Chery',
        chevrolet: 'Chevrolet',
        citroen: 'Citroen',
        fiat: 'Fiat',
        ford: 'Ford',
        'harley-davidson': 'Harley-Davidson',
        honda: 'Honda',
        'honda motos': 'Honda Motos',
        hyundai: 'Hyundai',
        jeep: 'Jeep',
        kia: 'Kia',
        'mercedes-benz': 'Mercedes-Benz',
        mini: 'Mini',
        mitsubishi: 'Mitsubishi',
        nissan: 'Nissan',
        peugeot: 'Peugeot',
        renault: 'Renault',
        toyota: 'Toyota',
        volks: 'Volkswagen',  // Add 'volks' for Volkswagen
        vw: 'Volkswagen',     // Add 'vw' for Volkswagen
        volvo: 'Volvo',
        yamaha: 'Yamaha'
    };
    
    const getSellerBrand = (sellerName) => {
        // Convert sellerName to lowercase for case-insensitive matching
        const lowerCaseSellerName = sellerName.toLowerCase();
    
        // Loop through the brandKeywords dictionary
        for (const keyword in brandKeywords) {
            if (lowerCaseSellerName.includes(keyword)) {
                setSellerBrandName(brandKeywords[keyword]);
                setBrandName(brandKeywords[keyword]);
                return;
            }
        }
        // Default case if no match is found
        setSellerBrandName('Genuína');
    };
    
    useEffect(() => {
        if (configData.defaultStore !== 'UmuaramaHD') {
            if (selectedBrand && selectedBrand !== '') {
                setBrandName(toTitleCase(selectedBrand));
            } else {
                // setBrandName('Genuína');
                if (selectedSeller && selectedSeller.name) {
                    getSellerBrand(selectedSeller.name);
                }
            }
        } else {
            setBrandName('Harley Davidson');
        }
    }, [selectedSeller]);

    // useEffect(() => {
    //     //console.log('InternalID: ', InternalID);
    //     //console.log('vehicleNote: ', vehicleNote);
    //     //console.log('product page: ', product);
    // }, [vehicleNote, product, InternalID]);

    const sortSellersByLowestPrice = (sellers) => {
        return [...sellers].sort((a, b) => parseFloat(a.price_ecommerce) - parseFloat(b.price_ecommerce));
    };

    const sortSellersByHighestDiscount = (sellers) => {
        return [...sellers].sort((a, b) => {
            const discountA = a.price ? parseFloat(a.price) - parseFloat(a.price_ecommerce) : 0;
            const discountB = b.price ? parseFloat(b.price) - parseFloat(b.price_ecommerce) : 0;
    
            //console.log(`Seller: ${a.name}, Discount: ${discountA}`);
            //console.log(`Seller: ${b.name}, Discount: ${discountB}`);
    
            return discountB - discountA;
        });
    };

    const sortSellersAlphabetically = (sellers) => {
        const sorted = [...sellers].sort((a, b) => a.name.localeCompare(b.name));
        return sorted;
    };

    const sortSellersByLowestDeliveryPrice = (sellers) => {
        return sellers.sort((a, b) => {
            const shippingA = getLowestShippingInfo(shippingListbySeller[a.name]?.shippingOptions);
            const shippingB = getLowestShippingInfo(shippingListbySeller[b.name]?.shippingOptions);
    
            // Sellers with no shipping options go last
            if (shippingA.lowestPrice === '-' && shippingB.lowestPrice === '-') return 0;
            if (shippingA.lowestPrice === '-') return 1;
            if (shippingB.lowestPrice === '-') return -1;
    
            return parseFloat(shippingA.lowestPrice) - parseFloat(shippingB.lowestPrice);
        });
    };
    
    const sortSellersByLowestDeliveryTime = (sellers) => {
        return sellers.sort((a, b) => {
            const shippingA = getLowestShippingInfo(shippingListbySeller[a.name]?.shippingOptions);
            const shippingB = getLowestShippingInfo(shippingListbySeller[b.name]?.shippingOptions);
    
            // Sellers with no shipping options go last
            if (shippingA.lowestTime === '-' && shippingB.lowestTime === '-') return 0;
            if (shippingA.lowestTime === '-') return 1;
            if (shippingB.lowestTime === '-') return -1;
    
            return parseInt(shippingA.lowestTime) - parseInt(shippingB.lowestTime);
        });
    };
    
    const sortSellersByLowestTotalPrice = (sellers) => {
        return sellers.sort((a, b) => {
            const shippingA = getLowestShippingInfo(shippingListbySeller[a.name]?.shippingOptions);
            const shippingB = getLowestShippingInfo(shippingListbySeller[b.name]?.shippingOptions);
    
            // Sellers with no shipping options go last
            if (shippingA.lowestPrice === '-' && shippingB.lowestPrice === '-') return 0;
            if (shippingA.lowestPrice === '-') return 1;
            if (shippingB.lowestPrice === '-') return -1;
    
            const totalA = parseFloat(a.price_ecommerce) + parseFloat(shippingA.lowestPrice);
            const totalB = parseFloat(b.price_ecommerce) + parseFloat(shippingB.lowestPrice);
    
            return totalA - totalB;
        });
    };

    const getLowestShippingInfo = (shippingOptions) => {
        if (!shippingOptions || shippingOptions.length === 0) {
            return { lowestPrice: '-', lowestTime: '-' };
        }

        let lowestPrice = Infinity;
        let lowestTime = Infinity;

        shippingOptions.forEach(option => {
            const price = parseFloat(option.deliveryPrice.replace(',', '.'));
            const time = parseInt(option.deliveryTime);

            if (price < lowestPrice) lowestPrice = price;
            if (time < lowestTime) lowestTime = time;
        });

        return {
            lowestPrice: lowestPrice === Infinity ? '-' : lowestPrice.toFixed(2),  // formatted as a number
            lowestTime: lowestTime === Infinity ? '-' : `${lowestTime} dias úteis`
        };
    };

    const handleSortChange = (event) => {
        const sortOption = event.target.value;
        setSortSellers(event.target.value);
    
        let sortedList = [...product.getSellers()];
    
        switch (sortOption) {
            case 'lowestPrice':
                sortedList = sortSellersByLowestPrice(sortedList);
                break;
            case 'highestDiscount':
                sortedList = sortSellersByHighestDiscount(sortedList);
                break;
            case 'alphabetical':
                sortedList = sortSellersAlphabetically(sortedList);
                break;
            case 'lowestDeliveryPrice':
                sortedList = sortSellersByLowestDeliveryPrice(sortedList);
                break;
            case 'lowestDeliveryTime':
                sortedList = sortSellersByLowestDeliveryTime(sortedList);
                break;
            case 'lowestTotalPrice':
                sortedList = sortSellersByLowestTotalPrice(sortedList);
                break;
            default:
                break;
        }
    
        setSortedSellers(sortedList);
    };

    const updateSorting = () => {
        let sortedList = [...product.getSellers()];
    
        switch (sortSellers) {
            case 'lowestPrice':
                sortedList = sortSellersByLowestPrice(sortedList);
                break;
            case 'highestDiscount':
                sortedList = sortSellersByHighestDiscount(sortedList);
                break;
            case 'alphabetical':
                sortedList = sortSellersAlphabetically(sortedList);
                break;
            case 'lowestDeliveryPrice':
                sortedList = sortSellersByLowestDeliveryPrice(sortedList);
                break;
            case 'lowestDeliveryTime':
                sortedList = sortSellersByLowestDeliveryTime(sortedList);
                break;
            case 'lowestTotalPrice':
                sortedList = sortSellersByLowestTotalPrice(sortedList);
                break;
            default:
                break;
        }
    
        setSortedSellers(sortedList);
    }
    
    if (loading) {
        return (
            <div className='main center fadeIn'>
                <div className="loadingContainer center">
                    <CircularProgress
                        style={{padding: '20px'}}
                        sx={{
                            color: 'var(--mainColor)'
                        }}/>
                </div>
            </div>
        );
    };

    const discountPercent = hasSale ? formatPercent(product.getDiscountPercentage(selectedSeller.price, selectedSeller.price_ecommerce)) : '';

    const sizeOptions = {
        'XS': 'PP',
        'S': 'P',
        'M': 'M',
        'L': 'G',
        'XL': 'GG',
        '2XL': '3G',
    }

    const outOfStockSizes = [
        'XS', '2XL'
    ]

    const colorOptions = {
        'preto': '#000000',
        'cinza': '#A4A4A3',
        'branco': 'var(--veryVeryLightGray)',
        'amarelo': '#F8D132',
        'azul': '#334F9A',
        'verde': '#52A346',
        'vermelho': '#D03532',
        'laranja': '#D96828',
        'rosa': '#D97996',
        'marrom': '#6D573E',
        'roxo': '#653A78',
    }

    const outOfStockColors = [
        'amarelo', 'verde', 'roxo'
    ]

    const handleSizeSelect = (value) => {
        setSelectedSize(value);
    }

    const handleColorSelect = (key) => {
        setSelectedColor(key);
    }

    const totalScore = productReview.reduce((acc, review) => acc + review.score, 0);
    const averageScore = totalScore / productReview.length;
    const roundedScore = Math.floor(averageScore);

    const placeholderSellerReviews = [4,5,4,4,5,3]

    const avgScore = placeholderSellerReviews.length > 0 
    ? (placeholderSellerReviews.reduce((sum, score) => sum + score, 0) / placeholderSellerReviews.length)
    : 0;

    // Display avgScore with one decimal place
    const avgScoreDisplay = avgScore.toFixed(1);

    // Count the full stars and empty stars based on rounded avgScore
    const roundedStars = Math.round(avgScore);

    const prodDescription = product?.getDescription() ?? '';
    const prodTitleComplement = product?.getTitleComplement(product.supplier) ?? '';
    const prodDsNumber = product?.ds_number ?? '';

    const gtmAddToCart = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    currency: 'BRL',
                    add: {
                        products: [{
                            id: product.ds_number,
                            name: product.description,
                            price: selectedSeller.price_ecommerce,
                            quantity: 1,
                            seller: selectedSeller.name,
                            discount: discountPercent,
                        }]
                    }
                }
            });
        } else {
            console.warn("dataLayer is not defined");
        }
    };

    const addToCart = () => {
        dispatch({
            type: ADD_TO_CART,
            payload: { 
                id: product.ds_number,
                title: product.description,
                originalPrice: selectedSeller.price,
                price: selectedSeller.price_ecommerce,
                img: product.images[0],
                itemQty: 1,
                sellerInfo: selectedSeller,
                measures: product.measures,
                hasSale: hasSale,
                supplier: product.getTitleComplement(product.supplier)
            }
        });
        gtmAddToCart();
    };

    const purchase = () => {
        addToCart();
        navigate('/checkout');
    };

    const gtmViewItem = () => {
        setTriggerGtmViewItem(false);
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'view_item',
                ecommerce: {
                    currency: 'BRL',
                    // Use an object instead of an array since it's a single item view
                    item: {
                        id: product.ds_number,
                        name: product.description,
                        price: selectedSeller.price_ecommerce,
                        quantity: 1, // Optional; typically not necessary for a view event
                        seller: selectedSeller.name,
                        discount: discountPercent,
                    }
                }
            });
        } else {
            console.warn("dataLayer is not defined");
        };
    };
    

    if(triggerGtmViewItem && product) {
        gtmViewItem();
    }
    
    return (
            <div className='main fadeIn'>

                <Helmet>
                    <title>{`${prodDescription} ${prodDsNumber} ${prodTitleComplement} ${brandName} ${configData.defaultStore}`}</title>
                    <meta name="description" content={`Compre ${prodDescription} - ${prodDsNumber} e receba diretamente de uma concessionária ${brandName}!`} />
                    
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={`https://${configData.defaultStoreLink}/${prodDsNumber}`} />
                    <meta property="og:title" content={`${prodDescription} ${prodDsNumber} ${prodTitleComplement} ${brandName} ${configData.defaultStore}`} />
                    <meta property="og:description" content={`Compre ${prodDescription} - ${prodDsNumber} e receba diretamente de uma concessionária ${brandName}!`} />
                    <meta property="og:image" content={product.getImages('capa')} />

                    <meta property="twitter:card" content={product.getImages('capa')} />
                    <meta property="twitter:url" content={`https://${configData.defaultStoreLink}/${prodDsNumber}`} />
                    <meta property="twitter:title" content={`${prodDescription} ${prodDsNumber} ${prodTitleComplement} ${brandName} ${configData.defaultStore}`} />
                    <meta property="twitter:description" content={`Compre ${prodDescription} - ${prodDsNumber} e receba diretamente de uma concessionária ${brandName}!`} />
                    <meta property="twitter:image" content={product.getImages('capa')} />

                    <link rel="canonical" href={`https://${configData.defaultStoreLink}/${prodDsNumber}`} />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org/",
                            "@type": "Product",
                            name: product ? prodDescription : '',
                            image: product ? product.getImages('capa') : [],
                            description: product ? prodDescription + ' ' + prodDsNumber + ' ' + prodTitleComplement  : '',
                            brand: { "@type": "Brand", name: brandName },
                            offers: {
                                "@type": "Offer",
                                price: selectedSeller?.price_ecommerce || 0,
                                priceCurrency: "BRL",
                                availability: selectedSeller?.stock > 0 ? "InStock" : "OutOfStock",
                                seller: { "@type": "Organization", name: selectedSeller?.name || '' },
                            },
                        })}
                    </script>
                </Helmet>

                <div className='productPageBg' style={{backgroundImage: `url(${productPageBg})`}}>
                    {configData.defaultStore === 'UmuaramaHD' &&
                        <div className='umuaramaHDnav'>
                            <Home style={{color: 'var(--secondColor)', fontSize: '18px'}}/>
                            <a href='/'>Início</a>
                            <span>›</span>
                            {/* <a>'category'</a>
                            <span>›</span> */}
                            <span>{product.ds_number} - {`${prodDescription} ${prodTitleComplement}`}</span>
                        </div>
                    }
                </div>
                {/* <div className='pageContent' style={{zIndex: '1', marginTop: `${topMargin}`}}> */}
                <div className={`pageContent ${configData.defaultStore === 'UmuaramaHD' ? 'pageContentLargeTopMargin' : ''}`} style={{zIndex: '1'}}>
                    <div className='column' style={{gap: '20px'}}>
                        <div className='productResponsive'>
                            <div className='column productImgContainer'>
                                {product && product.getImages().length !== 0 ?
                                    <ProductImgSwiper
                                        images={product.getImages()}
                                    />
                                    : <img alt='Capa' src={defaultImage} loading='lazy' />
                                }
                            </div>

                            <div className='column' style={{gap: '20px'}}>
                                <div ref={destinationRef} className='productPageCard'>
                                    <div className='column' style={{display: 'flex', gap: '16px', padding: '10px'}}>
                                        
                                        <div className='row' style={{justifyContent: 'space-between', alignItems: 'center', gap: '10px'}}>
                                            <div className='column'>
                                                <h1>{`${prodDescription} ${prodTitleComplement}`}</h1>
                                                <div className='column' style={{gap: '4px'}}>
                                                    <span className='grayedText'>{prodDsNumber}</span>
                                                    {vehicleNote != '' &&
                                                        <span className='grayedText'>Obs: <span className='noteRow'>{vehicleNote}</span></span>
                                                    }
                                                </div>
                                            </div>
                                            <ShareButton/>
                                        </div>

                                        {/* <div className='row defaultBorderedItem' style={{justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div className='column' style={{gap: '10px'}}>
                                                <span className='grayedText'>Código: <span className='textColor'>{product.ds_number}</span></span>
                                                {vehicleNote != '' &&
                                                    <span className='grayedText'>Obs: <span className='noteRow'>{vehicleNote}</span></span>
                                                }
                                            </div>
                                            <a onClick={() => scrollToRef('productDetails', 100)}>Mais detalhes</a>
                                        </div> */}
                                        
                                        {/* {configData.defaultStore === 'UmuaramaHD' && productReview.length > 0 &&
                                            <div className='defaultBorderedItem row clickArea' style={{alignItems: 'center', gap: '4px'}} onClick={() => scrollToRef('productReviews', 100)}>
                                                <h2>{averageScore.toFixed(1)}</h2>
                                                <div className='row' style={{color: 'var(--secondColor)'}}>
                                                    {[...Array(5)].map((_, index) => (
                                                        index < roundedScore ? <Star key={index} /> : <StarOutline key={index} />
                                                    ))}
                                                </div>
                                                <span>({productReview.length} {productReview.length > 1 ? 'avaliações': 'avaliação'})</span>
                                            </div>
                                        } */}

                                        {isClothing &&
                                            <div className='column defaultBorderedItem' style={{gap: '10px'}}>
                                                <div className='column' style={{ gap: '4px' }}>
                                                    <span>Tamanho:</span>
                                                    <div className='row' style={{ gap: '6px', flexWrap: 'wrap' }}>
                                                        {Object.entries(sizeOptions).map(([key, value]) => {
                                                            const isOutOfStock = outOfStockSizes.includes(key);
                                                            return (
                                                                <div
                                                                    key={key}
                                                                    className={`defaultBorderedItem sizePicker ${
                                                                        selectedSize === value ? 'selectedSizePicker' : ''
                                                                    } ${isOutOfStock ? 'outOfStock' : ''}`}
                                                                    onClick={!isOutOfStock ? () => handleSizeSelect(value) : undefined}
                                                                >
                                                                    <span>{value}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className='column' style={{ gap: '6px' }}>
                                                    <span>Cor: {selectedColor}</span>
                                                    <div className='row' style={{ gap: '6px', flexWrap: 'wrap' }}>
                                                        {Object.entries(colorOptions).map(([key, value]) => {
                                                            const isOutOfStock = outOfStockColors.includes(key);
                                                            return (
                                                                <div
                                                                    key={key}
                                                                    className={`defaultBorderedItem colorPicker ${
                                                                        selectedColor === key ? 'selectedColorPicker' : ''
                                                                    } ${isOutOfStock ? 'outOfStock' : ''}`}
                                                                    onClick={!isOutOfStock ? () => handleColorSelect(key) : undefined}
                                                                >
                                                                    <div
                                                                        className='colorPickerColor'
                                                                        style={{ backgroundColor: `${value}` }}
                                                                    ></div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className='row column640' style={{gap: '10px'}} id='selectedSeller'>
                                            <div className={`column productInfoDiv defaultBorderedItem ${highlightSeller ? 'highlight' : ''}`} style={{ flex: '1', alignItems: 'start', justifyContent: 'space-around' }} ref={destinationRef}>
                                                <div className='row' style={{width: '100%', justifyContent: 'space-between'}}>
                                                    <div className='column'>
                                                        {hasSale &&
                                                            <span className='originalPrice'>{formatPrice(selectedSeller.price)}</span>
                                                        }
                                                        <div className='row' style={{alignItems: 'center', gap: '10px'}}>
                                                            {hasSellers && <h2 className='mainPrice' style={{fontSize: '32px'}}>{formatPrice(selectedSeller.price_ecommerce)}</h2>}
                                                            {hasSale &&
                                                                <Button
                                                                    className='smallButton discountHighlight'
                                                                    label={`${discountPercent} off`}
                                                                />
                                                            }
                                                        </div>
                                                        {hasSellers && 
                                                            <span className='grayedText'>à vista ou em até <span style={{color: 'var(--textColor)'}}>{calculateMaxParcels(selectedSeller.price_ecommerce)}</span></span>
                                                        }
                                                    </div>
                                                    {selectedBrand && selectedBrand !== ''
                                                        ? (
                                                            <img className='defaultBorderedItem sellerBrand' src={BrandsList[brandName]} alt={brandName} loading='lazy'/>
                                                        ) : (
                                                            BrandsList[sellerBrandName] && 
                                                            <img className='defaultBorderedItem sellerBrand' src={BrandsList[sellerBrandName]} alt={sellerBrandName} loading='lazy'/>
                                                        )
                                                    }
                                                </div>

                                                {selectedSeller.stock > 0
                                                    ?
                                                        <div className='column'>
                                                            {selectedSeller.stock == 1 &&
                                                                <span className='sellerHighlight'>Última unidade</span>
                                                            }
                                                            {selectedSeller.stock > 1 &&
                                                                <>
                                                                    {selectedSeller.stock > 100
                                                                        ? <span style={{color: 'var(--grayedText)'}}><span className='sellerHighlight'>+100</span> unidades</span>
                                                                        : <span style={{color: 'var(--grayedText)'}}><span className='sellerHighlight'>{selectedSeller.stock.toLocaleString('pt-BR')}</span> unidades</span>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    : <h2 style={{fontStyle: 'italic', opacity: '0.7'}}>Indisponível :(</h2>
                                                }

                                                {selectedSeller &&
                                                <div className='row' style={{width: '100%', justifyContent: 'space-between', gap: '10px', alignItems: 'end', borderTop: '1px solid var(--lightGray)', paddingTop: '10px'}}>
                                                    <div className='column' style={{justifyContent: 'start'}}>

                                                        <span style={{color: 'var(--grayedText)'}}>Loja <span className='sellerHighlight'>{selectedSeller.name}</span></span>
                                                        
                                                        {configData.defaultStore != 'UmuaramaHD' &&
                                                            <div className='row' style={{gap: '6px', alignItems: 'center'}}>
                                                                <div className='row'>
                                                                    {Array(roundedStars).fill().map((_, index) => (
                                                                        <Star className='reviewStarStaticMedium' key={index} />
                                                                    ))}
                                                                    {Array(5 - roundedStars).fill().map((_, index) => (
                                                                        <StarOutline className='reviewStarStaticMedium' key={index + roundedStars} />
                                                                    ))}
                                                                </div>
                                                                <span>({avgScoreDisplay})</span>
                                                            </div>
                                                        }

                                                    </div>

                                                    {product && product.getSellers().length > 1 &&
                                                        <Button
                                                            className='buttonColorMain smallButton'
                                                            label='trocar loja'
                                                            iconStart={<SwapVert/>}
                                                            onClick={() => scrollToRef('sellersList', 100)}
                                                        />
                                                    }
                                                </div>
                                                }
                                            </div>
                                            {selectedSeller && selectedSeller.stock > 0 &&
                                                <div className='sellerButtonsColumn' style={{flex: '1'}}>
                                                    <Button
                                                        onClick={() => purchase()}
                                                        className='buttonColorThird largeButton'
                                                        label='Comprar agora'
                                                    />
                                                    <Button
                                                        onClick={() => addToCart()}
                                                        className='buttonColorMain largeButton'
                                                        label='Adicionar ao carrinho'
                                                    />

                                                    {!loadingShipping
                                                        ?
                                                            <>
                                                                {!hasShipping
                                                                    ?
                                                                        (<div className='column fadeIn' style={{gap: '10px'}}>
                                                                            <form className='row' style={{gap: '10px'}} onSubmit={handleCEPSubmit}>
                                                                                <input
                                                                                    type="text"
                                                                                    id="cep"
                                                                                    value={cep}
                                                                                    onChange={handleCEP}
                                                                                    placeholder={cep || "CEP..."}
                                                                                    maxLength={9}
                                                                                />
                                                                                <Button
                                                                                    className='buttonColorThird smallButton'
                                                                                    iconEnd={<ShippingIcon/>}
                                                                                    label='Calcular frete'
                                                                                    type='submit'
                                                                                />
                                                                            </form>
                                                                        </div>)
                                                                    :
                                                                        <div id='calculatedShipping' className={`row defaultBorderedItem ${highlightShipping ? 'highlight' : ''}`} style={{justifyContent: 'space-between'}}>
                                                                            <div className='column' style={{gap: '6px'}}>
                                                                                <span>Envio para <span className='defaultGrayBox' style={{padding: '4px'}}>{cep}</span>:</span>
                                                                                    <div className='column' style={{gap: '6px', marginTop: '6px'}}>
                                                                                        {shippingListbySeller[selectedSeller.name]?.shippingOptions.map((shippingOption, index) => (
                                                                                            <span key={index} className='grayedText'>{shippingOption.name}: <span style={{color: 'var(--textColor)'}}> R$ {shippingOption.deliveryPrice}</span>
                                                                                                <span style={{ color: 'var(--textColor)' }}> - {shippingOption.deliveryTime} dias úteis</span>
                                                                                            </span>
                                                                                        ))}
                                                                                    </div>
                                                                            </div>
                                                                            <a className='colorTextLink' onClick={() => resetShipping()}>alterar</a>
                                                                        </div>
                                                                }
                                                                {sortedSellers.length >= 1 &&
                                                                    <>
                                                                        {cep !== '' && cep.length == 9 &&
                                                                            <>
                                                                                {!loadingMultiShip
                                                                                    ?
                                                                                        <>
                                                                                            {product && product.getSellers().length > 1 && multiShipCEP == '' &&
                                                                                                <Button
                                                                                                    className='buttonStyle buttonColorThird smallButton'
                                                                                                    label={`Calcular em todas as ${sortedSellers.length} lojas`}
                                                                                                    onClick={handleCalculateShippingForAll}
                                                                                                />
                                                                                            }
                                                                                        </>
                                                                                    :
                                                                                        <div className='column center' style={{height: '100px'}}>
                                                                                            <CircularProgress
                                                                                                sx={{ color: 'var(--secondColor)' }}
                                                                                            />
                                                                                            <span>{`Calculando entregas (${multiShipProgress})`}</span>
                                                                                        </div>
                                                                                }
                                                                            </>
                                                                            // <Button
                                                                            //     className='buttonColorMain largeButton disableButton'
                                                                            //     label={`Calcular em todas as ${sortedSellers.length} lojas`}
                                                                            // />
                                                                        } 
                                                                    </>
                                                                }
                                                                
                                                            </>
                                                        :
                                                            <div className='row center' style={{height: '100px'}}>
                                                                <CircularProgress
                                                                    sx={{ color: 'var(--secondColor)' }}
                                                                />
                                                            </div>
                                                    }

                                                </div>
                                            }
                                        </div>
                                        
                                        {configData.defaultStore !== 'UmuaramaHD' &&
                                            <div className='whatsappCtaContainerProductPage'>
                                                <h2 className='GoParcaQuote'>Dúvidas sobre o produto ou compatibilidade?</h2>
                                                <div className='row center' style={{alignItems: 'space-evenly'}}>
                                                    <div className='row center'>
                                                        <img src={GoParca_Show_01} alt='GoParts_GoParça_Atendimento' className='GoParcaShowProduct' loading='lazy'/>
                                                        {/* <span className='mobileSpan'>{whatsappInfo}</span> */}
                                                    </div>
                                                    <div className='column' style={{gap: '6px', margin: '10px 0'}}>
                                                        <Button
                                                            className='buttonStyle buttonColorGreen smallButton'
                                                            label='Fale com um especialista'
                                                            iconStart={<WhatsappIcon/>}
                                                            onClick={() => handleWhatsappClick(selectedBrand, selectedModel, selectedYear, selectedVersion)}
                                                        />
                                                        <span className='deskSpan'>{whatsappInfo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                {product && product.getSellers().length > 1 ? <div className='productPageCard' id='sellersList'>
                                    <h2 style={{padding: '10px 0 0 10px'}}>Mais lojas:</h2>
                                    <div className='row center' style={{padding: '10px', gap: '10px'}}>
                                        <Sort/>
                                        <span style={{whiteSpace: 'nowrap'}}>Ordenar por:</span>
                                        <select id='sellersSorter' onChange={handleSortChange}>
                                            <option value='lowestPrice' selected>Menor preço</option>
                                            <option value='highestDiscount'>Maior desconto</option>
                                            <option value='alphabetical'>Ordem alfabética</option>
                                            {shippingListbySeller.length != 0 ? <option value='lowestDeliveryPrice'>Menor preço de frete</option> : <option value='lowestDeliveryPrice' disabled>Menor preço de frete</option>}
                                            {shippingListbySeller.length != 0 ? <option value='lowestDeliveryTime'>Menor prazo de entrega</option> : <option value='lowestDeliveryTime' disabled>Menor prazo de entrega</option>}
                                            {shippingListbySeller.length != 0 ? <option value='lowestTotalPrice'>Menor preço total com frete</option> : <option value='lowestTotalPrice' disabled>Menor preço total com frete</option>}
                                        </select>
                                    </div>
                                    <div className='sellersList'>
                                        <div className={`${!isSellersListExpanded ? 'sellersShort' : 'sellersLong'}`}>
                                            {sortedSellers.slice(0, isSellersListExpanded || sortedSellers.length <= 3 ? sortedSellers.length : 3).map(seller => (
                                                <div key={seller.name}>
                                                    <SellerTile
                                                        onClick={() => handleSellerClick(seller)}
                                                        isSelected={selectedSeller.name === seller.name}
                                                        name={seller.name}
                                                        originalPrice={seller.price}
                                                        price={seller.price_ecommerce}
                                                        logo={brandName}
                                                        // logo={productPrefix}
                                                        stock={seller.stock}
                                                        calculatedShipping={shippingListbySeller[seller.name]}
                                                        isLoadingShipping={
                                                            (multiShipProgress ? !shippingListbySeller.hasOwnProperty(seller.name) : false) ||
                                                            (loadingShipping ? (!shippingListbySeller.hasOwnProperty(seller.name) && seller.name === selectedSeller.name) : false)
                                                        }
                                                        avgScoreDisplay={avgScoreDisplay} // placeholder
                                                        roundedStars={roundedStars} // placeholder
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        {sortedSellers.length > 3 &&
                                            <>
                                                {!isSellersListExpanded
                                                    ?
                                                        <Button
                                                            className='buttonColorSecond smallButton'
                                                            label='Ver mais lojas'
                                                            onClick={() => {handleExpandSellers()}}
                                                        />
                                                    :
                                                        <Button
                                                            className='buttonColorSecond smallButton'
                                                            label='Ver menos lojas'
                                                            onClick={() => {handleShrinkSellers()}}
                                                        />
                                                }
                                            </>
                                        }
                                    </div>
                                </div> : null}

                                <div className='productPageCard' id='productDetails'>
                                    <div className='column' style={{padding: '10px'}}>
                                        <h2>Detalhes</h2>
                                        <div className='column' style={{padding: '10px 6px', gap: '14px'}}>

                                            <div className='productSpecRow'>
                                                <div className='specIcon'>
                                                    <Barcode/>
                                                </div>
                                                <span className='grayedText'>Código: <span className='textColor'>{product.ds_number}</span></span>
                                            </div>
                                            
                                            {vehicleNote != '' &&
                                                <div className='productSpecRow'>
                                                    <div className='specIcon' style={{backgroundColor: '#fff5ad'}}>
                                                        <ReportOutlined/>
                                                    </div>
                                                    <span className='grayedText'>Obs: <span className='textColor'>{vehicleNote}</span></span>
                                                    {/* <span className='grayedText'>Obs: <span className='noteRow'>{vehicleNote}</span></span> */}
                                                </div>
                                            }

                                            <div className='productSpecRow'>
                                                <div className='specIcon'>
                                                    <Certified/>
                                                </div>
                                                <span style={{color: 'var(--grayedText)', marginRight: '4px'}}>Marca: <span className='textColor'>{brandName}</span></span>
                                            </div>

                                            <div className='productSpecRow'>
                                                <div className='specIcon'>
                                                    <NewProduct/>
                                                </div>
                                                <div className='column'>
                                                    <span style={{color: 'var(--grayedText)', marginRight: '4px'}}>Estado:
                                                        <span className='textColor'> Novo*</span>
                                                    </span>
                                                    <span style={{opacity: '0.5', fontStyle: 'italic'}}>*Todos os produtos desta loja são novos e originais das concessionárias.</span>
                                                </div>
                                            </div>

                                            <div className='productSpecRow'>
                                                <div className='specIcon'>
                                                    <Straighten/>
                                                </div>
                                                <span style={{color: 'var(--grayedText)', marginRight: '4px'}}>Dimensões (embalado):
                                                    <span className='textColor'> {product.measures.height} x {product.measures.width} x {product.measures.depth} cm</span>
                                                </span>
                                            </div>

                                            <div className='productSpecRow'>
                                                <div className='specIcon'>
                                                    <Weight/>
                                                </div>
                                                <span style={{color: 'var(--grayedText)', marginRight: '4px'}}>Peso:
                                                    {product.measures.weight >= 1000
                                                        ? (
                                                            <span className='textColor'> {new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(product.measures.weight / 1000)} kg</span>
                                                        ) : (
                                                            <span className='textColor'> {product.measures.weight} g</span>
                                                        )
                                                    }
                                                </span>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                                
                                {/* {configData.defaultStore === 'UmuaramaHD' &&
                                    <div className='productPageCard' id='productReviews'>
                                        <div className='productPageCard' style={{alignItems: 'start'}}>
                                            <div className='column' style={{padding: '10px'}}>
                                                <h2>Avaliações</h2>
                                                <div className='row' style={{alignItems: 'center', gap: '4px', marginLeft: '6px'}}>
                                                    <h2>{productReview.length > 0 ? averageScore.toFixed(1) : ''}</h2>
                                                    <div className='row' style={{color: 'var(--secondColor)'}}>
                                                        {[...Array(5)].map((_, index) => (
                                                            index < roundedScore ? <Star key={index} /> : <StarOutline key={index} />
                                                        ))}
                                                    </div>
                                                    {productReview.length > 0 &&
                                                        <span>({productReview.length} {productReview.length > 1 ? 'avaliações': 'avaliação'})</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className='column' style={{padding: '10px'}}>
                                                <h2>Comentários</h2>
                                                <div className='column' style={{fontStyle: 'italic', gap: '6px', padding: '10px 0', marginLeft: '6px'}}>
                                                    {productReview
                                                        .filter(review => review.comment) // Filter out reviews without comments
                                                        .sort((a, b) => b.score - a.score) // Sort reviews by score in descending order
                                                        .slice(0, 3) // Only show the top 3 reviews
                                                        .map((review, index) => (
                                                            <div key={index} className='column' style={{ marginBottom: '10px' }}>
                                                                <div className='row' style={{ alignItems: 'center', gap: '4px'}}>
                                                                    <h2 style={{fontSize: '16px'}}>{review.score}</h2>
                                                                    <div className='row' style={{color: 'var(--secondColor)'}}>
                                                                        {[...Array(5)].map((_, i) => (
                                                                            i < review.score ? <Star key={i} style={{fontSize: '14px'}}/> : <StarOutline key={i} style={{fontSize: '14px'}}/>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <span>"{review.comment}"</span>
                                                            </div>
                                                        ))}
                                                    {productReview.filter(review => review.comment).length === 0 && (
                                                        <span>"Ainda não há comentários para este produto."</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row center' style={{padding: '0 20px 20px 20px'}}>
                                            <Button
                                                className='buttonColorMain smallButton'
                                                label='Avaliar'
                                            />
                                        </div>
                                    </div>
                                } */}
                            </div>
                        </div>

                        <div className='homeSectionContainer' style={{padding: '0'}}>
                            <div className='rowToColumn800 homeSectonsBorder paymentContainer' style={{backgroundColor: 'var(--veryLightGray) !important'}}>
                                {configData.defaultStore === 'GoParts' &&
                                    <div className='homeSectionContainer center payRowLeft'>
                                        <img src={GoParca_Zen} alt='GoParts_GoParça_Segurança' className='paymentImg' loading='lazy'/>
                                    </div>
                                }
                                <div className='homeSectionContainer center payRowRight'>
                                    <h2 style={{fontSize: '24px'}}>Compre com Segurança e Tranquilidade</h2>
                                    <div className='row' style={{gap: '10px', alignItems: 'center'}}>
                                        <div className='column' style={{gap: '10px', alignItems: 'start'}}>
                                            <span>Compre peças originais para o seu veículo sem preocupações, em um ambiente digital seguro contra falsificações e má procedência.</span>
                                            <span>Confira nossa <a href='/privacidade'>Política de Privacidade</a></span>
                                        </div>
                                        {configData.defaultStore === 'GoParts' &&
                                            <img src={GoParca_Zen_2} alt='GoParts_GoParça_Segurança' className='paymentImgMobile' loading='lazy'/>
                                        }
                                    </div>
                                    <div className='row' style={{flexWrap: 'wrap', gap: '20px'}}>
                                        {CardOperators.map(item => (
                                            <img src={item} alt={`${item.split('/').pop().split('.')[0]} logo`} className='cardOpImg' loading='lazy'/>
                                        ))}
                                    </div>
                                    <div className='row' style={{flexWrap: 'wrap', gap: '20px', alignItems: 'center', borderTop: '1px solid rgba(0, 0, 0, 0.12)', paddingTop: '20px'}}>
                                        <div className='antifraudContainer' style={{backgroundColor: 'var(--lightGray)'}}>
                                            <img src={antifraudIcon} alt='GoParts_Antifraude' loading='lazy'/>
                                            <div className='column center' style={{gap: '2px'}}>
                                                <h3>PAGAMENTO<br/>SEGURO</h3>
                                                {/* <span>com sistema<br/>antifraude</span> */}
                                            </div>
                                        </div>
                                        <img src={Lgpd} alt='GoParts_LGPD' className='payImg' loading='lazy'/>
                                        <img src={Ssl} alt='GoParts_SSL' className='payImg' loading='lazy'/>
                                        {/* <img src={Pix} className='payImg' loading='lazy'/> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    );
}

export default ProductPage;