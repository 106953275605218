import React from 'react';
import '../../App.css';
import './CategoryTile.css';

function CategoryTile(props) {

    const handleCatClick = () => {
        props.onClick(props.name)
    }

    return (
        <div className={`catTileContainer ${props.imgSize === 'small' ? 'smallerContainer' : ''}`}
            onClick={handleCatClick}
            style={{ margin: props.noMargin ? props.noMargin : undefined }}
        >
            <img src={props.image} className={`${props.imgSize === 'small' ? 'catImgSmall' : 'catImg'}`}/>
            <div className='column center'>
                <h2>{props.name}</h2>
            </div>
        </div>
    );
}

export default CategoryTile;