import React from 'react';

export const AddCar = () => (
    <svg
        id="Camada_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width="20"
        height="20"
    >
        <defs>
        <style>{'.cls-1{fill:#e8eaed;stroke-width:0px;}'}</style>
        </defs>
        <path className="cls-1" d="M3.44,12.44c-.29.29-.44.65-.44,1.06s.15.77.44,1.06.65.44,1.06.44.77-.15,1.06-.44.44-.65.44-1.06-.15-.77-.44-1.06-.65-.44-1.06-.44-.77.15-1.06.44Z" />
        <path className="cls-1" d="M16,11.92v4.08H2v-5h9.4c-.43-.25-.83-.55-1.18-.89-.35-.34-.67-.71-.95-1.11H2.8l1.05-3h4.23c-.05-.33-.08-.67-.08-1s.03-.67.08-1H3.5c-.32,0-.6.09-.86.28-.26.18-.44.42-.54.72L0,11v8c0,.28.1.52.29.71.19.19.43.29.71.29h1c.28,0,.52-.1.71-.29.19-.19.29-.43.29-.71v-1h12v1c0,.28.1.52.29.71.19.19.43.29.71.29h1c.28,0,.52-.1.71-.29.19-.19.29-.43.29-.71v-7.67c-.32.15-.64.27-.97.38-.33.1-.68.17-1.03.22Z" />
        <path className="cls-1" d="M18.54,1.46c-.97-.98-2.15-1.46-3.54-1.46s-2.58.49-3.55,1.47-1.45,2.16-1.45,3.53.49,2.56,1.46,3.54c.97.98,2.15,1.46,3.54,1.46s2.56-.49,3.54-1.46c.98-.97,1.46-2.15,1.46-3.54s-.49-2.56-1.46-3.54ZM18.04,5.79h-2.25v2.25h-1.57v-2.25h-2.25v-1.57h2.25V1.96h1.57v2.25h2.25v1.57Z" />
        <path className="cls-1" d="M14.56,14.56c.29-.29.44-.65.44-1.06s-.15-.77-.44-1.06-.65-.44-1.06-.44-.77.15-1.06.44-.44.65-.44,1.06.15.77.44,1.06.65.44,1.06.44.77-.15,1.06-.44Z" />
    </svg>
);