import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import './ProductTile.css';
import Button from '../Button';
import ArrowRightIcon from "@mui/icons-material/ArrowRightRounded";
import { formatPrice, formatPercent } from '../../Utilities/Formats';
import defaultImageJpg from '../../images/defaultImage.webp';


function ProductTile({ product, vehicle, defaultImage = defaultImageJpg, isSmall }) {
  if(product === undefined) return null

  const getVehicleID = (vehicle) => {
    return vehicle.selectedVehicle?.InternalID ?? vehicle.InternalID;
  };
  const hasSale = (product.getSellers()).length > 0 ? product.sellers[0].price_ecommerce < product.sellers[0].price : false;
  const hasSellers = (product.getSellers()).length > 0 ? true : false;

  const vehicleID = getVehicleID(vehicle); // Use the function to get vehicle ID

  const vehicleNote = product.getNotes(vehicleID);
  
  // //console.log('product: ', product);

  const productCode = product.getCode();

  const gtmSelectItem = () => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'select_item',
            ecommerce: {
              currency: 'BRL',
              click: {  // This indicates a click action, not an add-to-cart action
                  products: [{
                      id: product.getCode(),
                      name: product.getDescription(),
                  }]
              }
          }
        });
    } else {
        console.warn("dataLayer is not defined");
    }
  };

  return (
    <>
      {productCode !== 'Sem cadastro de peça'
        ?
          <Link
            to={`/${product.getCode()}`}
            state={{ defaultImage: defaultImage, product: product }}
            className='tileClick'
            onClick={gtmSelectItem}
          >
            {/* {//console.log(product, defaultImage)} */}
            {!isSmall
              ?
                <div className='productTileContainer'>

                    <div className='productImgContainer'>
                      <img alt={`${product.getDescription()} - ${product.getCode()}`} src={product.getImages("capa") ? product.getImages("capa") : defaultImage} className='productImg' style={{opacity: `${!product.getImages("capa") ? '0.3' : '1.0'}`}}/>
                    </div>

                    <div className='tilePadding'>

                      <div className='column tileTitle'>
                          <h2 className='tileProductName'>
                            {`${product.getDescription()} ${product.getTitleComplement(product.supplier)}`}
                          </h2>
                          <h3 className='grayedText'>Código: {product.getCode()}</h3>
                          {vehicleNote != '' &&
                            <h3 className='grayedText'>Obs: <span className='noteRow'>{vehicleNote}</span></h3>
                          }
                      </div>

                      <div className='row tileInfo'>
                        <div className='column' style={{height: '100%', justifyContent: 'center'}}>
                          {hasSale ?
                            <>
                              <p className='originalPrice'>{formatPrice(product.getPrice("original"))}</p>
                              <h1 className='mainPrice'>{formatPrice(product.getPrice("ecommerce"))}</h1>
                            </>

                            : hasSellers 
                              ? <h1 className='mainPrice'>{formatPrice(product.getPrice("ecommerce"))}</h1>
                              : <h2 style={{color: 'var(--textColor)', fontStyle: 'italic', opacity: '0.7'}}>Indisponível :(</h2>
                          }  
                        </div>
                        <div className='column'>
                          {hasSale ?
                              <Button
                                className='smallButton discountHighlight'
                                label={`${formatPercent(product.getDiscountPercentage(product.getPrice("original"), product.getPrice("ecommerce")))} off`}
                                iconEnd={<ArrowRightIcon/>}
                              />
                            : 
                              <>
                                {hasSellers
                                  ?
                                    <Button
                                      // onClick={() => console.log('Button clicked')}
                                      className="buttonColorThird smallButton"
                                      label="Abrir"
                                      iconEnd={<ArrowRightIcon/>}
                                    />
                                  :
                                    <Button
                                      // onClick={() => console.log('Button clicked')}
                                      className="buttonColorLight smallButton"
                                      label="Abrir"
                                      iconEnd={<ArrowRightIcon/>}
                                    />
                                }
                              </>
                          }
                          
                        </div>
                      </div>

                    </div>
                </div>
              :
                <div className='smallProductTileContainer' style={{backgroundColor: 'white'}}>
                  <img className='smallTileImg' src={product.getImages("capa") ? product.getImages("capa") : defaultImage}/>
                  <div className='column' style={{paddingRight: '10px'}}>
                    <span>{`${product.getDescription()} ${product.getTitleComplement(product.supplier)}`}</span>
                    <span>{product.getCode()}</span>
                    <div className='row' style={{alignItems: 'center', justifyContent: 'start'}}>
                      {hasSale ?
                        <>
                          <p className='originalPrice'>{formatPrice(product.getPrice("original"))}</p>
                          <h1 className='mainPrice' style={{fontSize: '16px'}}>{formatPrice(product.getPrice("ecommerce"))}</h1>
                        </>

                        : hasSellers 
                          ? <h1 className='mainPrice' style={{fontSize: '16px'}}>{formatPrice(product.getPrice("ecommerce"))}</h1>
                          : <h2 style={{fontStyle: 'italic', opacity: '0.7', fontSize: '16px'}}>Indisponível :(</h2>
                      }  
                    </div>
                  </div>
                </div>
            }
          </Link>
        :
          <span className='grayedText'>Nenhum produto encontrado</span>
      }
    </>
  );
}

export default ProductTile;