import { storeInfo } from './Placeholders';
import { configData } from '../Whitelabel/websitesData';

export const handleWhatsappClick = (selectedBrand, selectedModel, selectedYear, selectedVersion, orderInfo) => {
    const whatsappDefaultLink = `https://api.whatsapp.com/send/?phone=%2B${configData.whatsappNumber}&text=Olá, tudo bem?`;

    let newLink = whatsappDefaultLink;

    if (selectedVersion) {
        newLink = `${whatsappDefaultLink} Meu veículo é um ${selectedBrand} ${selectedModel} ${selectedYear} ${selectedVersion}`;
    } else if (orderInfo) {
        newLink = `${whatsappDefaultLink} Meu pedido é o de Nº ${orderInfo}`;
    }

    window.open(newLink, '_blank');
};