import React, { useState, useEffect } from 'react';
import '../App.css';
import Button from '../Common/Button';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { CheckCircleOutline } from '@mui/icons-material';

function ResetPwd() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  }

  const resetClick = () => {
    if (email != '') {
      setResetSent(true);
    } else {
      alert('Insira um email de uma conta existente');
    }
  }

  const backToLogin = () => {
    navigate('/login');
  }

  return (
    <div className="main">
        <div className='pageContent column' style={{alignItems: 'center', gap: '10px'}}>
          <div className='card'>
            <div className='formContainer'>
              {!resetSent
                ?
                  <div className='column fadeIn' style={{padding: '20px', gap: '10px'}}>
                    <h2>Recuperar Senha</h2>
                    <input 
                      type='text' 
                      placeholder='email'
                      value={email}
                      onChange={handleEmail}
                    />
                    <Button
                      className='buttonStyle buttonColorMain largeButton'
                      label='Enviar email de recuperação'
                      onClick={resetClick}
                    />
                    <div className='column center'>
                      <NavLink to={'/login'}>
                        Voltar
                      </NavLink>
                    </div>
                  </div>
                :
                  <div className='column fadeIn' style={{padding: '20px', gap: '10px'}}>
                    <h2>Recuperar Senha</h2>
                    <div className='column  center'>
                      <CheckCircleOutline style={{fontSize: '64px', color: 'var(--highlightGreen)'}}/>
                    </div>
                    <span>Pronto! Um link de recuperação de senha foi enviado para o seu email :)</span>
                    <Button
                      className='buttonStyle buttonColorMain largeButton'
                      label='Ok'
                      onClick={backToLogin}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
    </div>
  );
}

export default ResetPwd;