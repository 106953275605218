import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyAvBf5Gte22AhysDj-RRWldD1JKWuEu2fk",
    authDomain: "pocrenault-daa71.firebaseapp.com",
    projectId: "pocrenault-daa71",
    storageBucket: "pocrenault-daa71.appspot.com",
    messagingSenderId: "1036472785243",
    appId: "1:1036472785243:web:97e4e814f5113244dee27e",
    measurementId: "G-0VGQ85YEKF"
};  

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// Initialize Firestore
// Initialize Firestore
const firestore = getFirestore(app);

export const functions = getFunctions(app);  // Export the functions instance

export { firestore };
