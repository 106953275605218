export const ADD_TO_CART = 'ADD_TO_CART';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const RESET_FROM_CART = 'RESET_FROM_CART';

// Function to load cart from localStorage
const loadCartFromSessionStorage = () => {
    const storedCart = localStorage.getItem('sessionCartItems');
    return storedCart ? JSON.parse(storedCart) : [];
};

// Initial state with cart loaded from localStorage
const estadoInicial = {
    carrinho: loadCartFromSessionStorage(),
};

// Function to save cart to localStorage
const saveCartToSessionStorage = (cart) => {
    localStorage.setItem('sessionCartItems', JSON.stringify(cart));
};

const cart = (state = estadoInicial, action) => {
    let updatedCart;
    
    switch (action.type) {
        case ADD_TO_CART:
            const existingItemIndex = state.carrinho.findIndex(item => item.id === action.payload.id);

            if (existingItemIndex !== -1) {
                updatedCart = state.carrinho.map((item, index) =>
                    index === existingItemIndex ? { ...item, itemQty: item.itemQty + 1 } : item
                );
            } else {
                updatedCart = [...state.carrinho, action.payload];
            }

            saveCartToSessionStorage(updatedCart); // Save updated cart to localStorage
            return { ...state, carrinho: updatedCart };

        case INCREASE_QUANTITY:
            updatedCart = state.carrinho.map((item) =>
                item.id === action.payload.id 
                    ? { ...item, itemQty: item.itemQty + 1 } 
                    : item
            );

            saveCartToSessionStorage(updatedCart); // Save updated cart to localStorage
            return { ...state, carrinho: updatedCart };

        case DECREASE_QUANTITY:
            updatedCart = state.carrinho.map((item) =>
                item.id === action.payload.id && item.itemQty > 1
                    ? { ...item, itemQty: item.itemQty - 1 }
                    : item
            );

            saveCartToSessionStorage(updatedCart); // Save updated cart to localStorage
            return { ...state, carrinho: updatedCart };

        case REMOVE_FROM_CART:
            updatedCart = state.carrinho.filter(item => item.id !== action.payload.id);

            saveCartToSessionStorage(updatedCart); // Save updated cart to localStorage
            return { ...state, carrinho: updatedCart };

        case RESET_FROM_CART:
            saveCartToSessionStorage([]); // Clear localStorage when cart is reset
            return { ...state, carrinho: [] };

        default:
            return state;
    }
};

export default cart;
