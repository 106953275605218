import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../App.css';

function ShippingTile({ id, name, isDefault, isSelected, shippingAddress, deliveryPrice, deliveryTime, onClick, ...props }) {
  return (
    <div id={`shipping-tile-${id}`}  className={`row defaultBorderedItem clickArea ${isSelected ? 'selectedItem' : 'itemSelect'}`} onClick={onClick} style={{gap: '10px', justifyContent: 'space-between', alignItems: 'center', paddingTop: 'var(--mediumPadding)', paddingBottom: 'var(--mediumPadding)'}}>
      <div className='row' style={{alignItems: 'center', gap: '10px'}}>
        <span>{name}</span>
        {/* <span className='grayedText'>endereço selecionado: {shippingAddress}</span> */}
      </div>
      <div className='column' style={{alignItems: 'end', gap: '4px', whiteSpace: 'nowrap'}}>
        {!deliveryPrice || !deliveryTime
          ? <span>Grátis</span>
          :
          <span>R$ {deliveryPrice} |
            <span> {deliveryTime}
                {deliveryTime >= 1
                    ? ' dias úteis'
                    : ' dia útil'
                }
            </span>
          </span>
        }
        {/* <span>{deliveryTime}
            {deliveryTime >= 1
                ? ' dias úteis'
                : ' dia útil'
            }
        </span> */}
      </div>
    </div>
  );
}

ShippingTile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ShippingTile;