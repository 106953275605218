import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSelectingVehicle } from '../reducers/vehicle'; // Import actions from vehicle.js
import '../App.css';
import './HeaderNavigation.css';
import ModelImgPH from '../images/models/8604.png';
import GenericVehicle from '../images/0.png';
import AddGenericVehicle from '../images/0 _add.png';
import { DirectionsCar, Add, Edit } from '@mui/icons-material';
import Button from '../Common/Button';
import { AddCar } from '../Utilities/Icons';
import { ModelImgs } from '../Utilities/ModelImgs';

function VehicleFilter() {
    const dispatch = useDispatch();
    const { selectedBrand, selectedModel, selectedYear, selectedVersion, modelSeries } = useSelector(state => state.vehicle);

    const handleEditVehicle = (e) => {
        e.preventDefault();
        dispatch(setIsSelectingVehicle(true));
    }

    const modelSeriesImg = (modelId) => {
        return ModelImgs[modelId] || GenericVehicle;
    };

    return (
        <div className='vehicleFilterContainer' onClick={handleEditVehicle}>
            {selectedVersion
                ?
                <div className='vehicleFilterRow center'>
                    <div className='vehicleFilterImgContainer center'>
                        <img className='vehicleFilterImg' alt={`${selectedBrand} ${selectedModel} ${selectedYear} ${selectedVersion}`} src={modelSeriesImg(modelSeries)} loading='lazy'/>
                    </div>
                    <span className='vehicleFilterName center'>{selectedBrand} - {selectedModel} - {selectedVersion}</span>
                    <div className='smallButtonCorner'>
                        <Edit style={{fontSize: '16px'}}/>
                    </div>
                </div>
                :
                <Button
                    className='buttonStyle buttonColorThird smallerButton hideLabelMobile vehicleFilterButton'
                    iconStart={<AddCar/>}
                    label='Filtrar'
                />
            }
        </div>
    );
}

export default VehicleFilter;
