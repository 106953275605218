class Product {
    constructor(active, description, ds_number, supplier, images, sellers, measures, listofPropertiesbyCar) {
      this.active = active;
      this.description = description;
      this.ds_number = ds_number;
      this.supplier = supplier;
      this.images = images;
      this.sellers = sellers;
      this.measures = measures;
      this.listofPropertiesbyCar = listofPropertiesbyCar;
    }
  
    getCode() {
        return this.ds_number;
    }

    getDescription() {
        return this.description
    }

    getImages(whatImage) {
        if (whatImage === "capa") return this.images[0];
        else return this.images;
    }

    getPrice(type) {
        const sellers = this.getSellers();
        if(type === "original") return sellers[0].price;
        else return sellers[0].price_ecommerce;
    }

    getDiscountPercentage(original, discounted) {
        if (original <= 0) {
            console.error("O preço original deve ser maior que zero.");
            return
        }
        const desconto = original - discounted;
        const percentualDesconto = (desconto / original);
        return percentualDesconto.toFixed(2); 
    }

    getMeasures(measure) {
        if(measure === "altura") return this.measures.height;
        else if(measure === "largura") return this.measures.width;
        else if(measure === "profundidade") return this.measures.depth;
        else if(measure === "peso") return this.measures.weight;
        else return this.measures;
    }

    getNotes(carId) {
        // Check if listofPropertiesbyCar is the expected string
        if (this.listofPropertiesbyCar === "listofPropertiesbyCar") {
            console.error("Error: listofPropertiesbyCar is incorrectly set to the string 'listofPropertiesbyCar'");
            return '';
        }
    
        const notes = this.listofPropertiesbyCar;
    
        if (notes && notes[carId]) {
            return notes[carId].join(', ');
        } else {
            return '';
        }
    }

    getSellers() {
        // //console.log('this.sellers: ', this.sellers);
        const sellersWithStock = this.sellers.filter(seller => seller.stock !== 0);
        // //console.log('sellersWithStock: ', sellersWithStock);
        return sellersWithStock;
    }

    getTitleComplement(IDsupplier) {
        if(IDsupplier.includes('GENUINA')) return "Original";
        else return IDsupplier;
    }
}
  
export default Product;