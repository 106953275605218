export function formatPrice(price) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    return formatter.format(price);
};

export function formatDate(dateString, short = false) {
    const date = new Date(dateString);
    const options = {
        year: short ? '2-digit' : 'numeric',  // Conditional logic to set the year format
        month: '2-digit',
        day: '2-digit'
    };
    return new Intl.DateTimeFormat('pt-BR', options).format(date);
};

export function formatTime(timeString) {
    const date = new Date(timeString);
    const options = { 
        hour: '2-digit', 
        minute: '2-digit', 
        hourCycle: 'h23'  // ensures 24-hour format without AM/PM
    };
    return new Intl.DateTimeFormat('pt-BR', options).format(date).replace(':', 'h');
};

export function formatDateAndTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const dateOptions = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    };
    const timeOptions = { 
        hour: '2-digit', 
        minute: '2-digit', 
        hourCycle: 'h23'  // ensures 24-hour format without AM/PM
    };

    const formattedDate = new Intl.DateTimeFormat('pt-BR', dateOptions).format(date);
    const formattedTime = new Intl.DateTimeFormat('pt-BR', timeOptions).format(date).replace(':', 'h');

    return `${formattedDate} ${formattedTime}`;
};

export function formatWeekday(dateTimeString, short = false) {
    const date = new Date(dateTimeString);  // This parses ISO 8601 formatted strings
    const options = {
        weekday: short ? 'short' : 'long',  // Choose "short" for abbreviated name, "long" for full name
        timeZone: 'UTC'  // Ensure consistent timezone handling, especially important for time-sensitive applications
    };
    return new Intl.DateTimeFormat('pt-BR', options).format(date);
};

export function formatPercent(value) {
    const percent = value * 100;  // Convert decimal to a percentage value
    const formattedPercent = percent.toFixed(1).replace('.', ',');  // Format to one decimal place and replace dot with comma
  
    // Check if the decimal part is zero
    if (formattedPercent.endsWith(',0')) {
      return `${parseInt(percent)}%`;  // Return the integer part only
    }
  
    return `${formattedPercent}%`;  // Return the formatted percentage
};

export function formatCep(cep) {
    return cep.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
};

