import FiltrosImg from '../images/categories/filtros_500px.webp';
import FreiosImg from '../images/categories/freios_500px.webp';
import SuspensaoDirecaoImg from '../images/categories/suspensaoedirecao_500px.webp';
import MotorPerifericosImg from '../images/categories/motoreperifericos_500px.webp';
import ArrefecimentoImg from '../images/categories/arrefecimento_500px.webp';
import IgnicaoEletricaImg from '../images/categories/ignicaoeeletrica_500px.webp';
import CorreiasTensoresRolamentosImg from '../images/categories/correiastensoreserolamentos_500px.webp';
import SistemasAlimentacaoCombustivelImg from '../images/categories/alimentacaodecombustivel_500px.webp';
import ArCondicionadoImg from '../images/categories/arcondicionado_500px.webp';
import TransmissaoEmbreagensImg from '../images/categories/transmissaoeembreagens_500px.webp';
import CoxinsSuportesImg from '../images/categories/coxins_e_suportes_500px.webp';
import LubrificantesFluidosImg from '../images/categories/lubrificantes_e_fluidos_500px.webp';
import CarroceriaImg from '../images/categories/bodyparts_500px.webp';
import IluminacaoImg from '../images/categories/iluminacao_500px.webp';
import KitsRevisaoReparoImg from '../images/categories/kit_reposicao_moto_1.1_500px.webp';

import { contentData, configData } from '../Whitelabel/websitesData';

import Pecas from '../images/UmuaramaHD/UHD_pecas_bg_01.webp';
import Acessorios from '../images/UmuaramaHD/UHD_acessorios_bg_01.webp';
import Boutique from '../images/UmuaramaHD/UHD_boutique_bg_01.webp';


import Alelo from '../images/alelo.webp';
import Amex from '../images/amex.webp';
import Elo from '../images/elo.webp';
import Hipercard from '../images/hipercard.webp';
import Itau from '../images/itau.webp';
import Jcb from '../images/jcb.webp';
import Master from '../images/master.webp';
import Visa from '../images/visa.webp';
import Diners from '../images/diners.webp';
import Pix from '../images/pix_logo.webp';

export const storeInfo = {
    defaultStore: 'GoParts',
    defaultContactEmail: 'contato@goparts.com.br',
    defaultStoreLink: 'goparts.com.br',
    launchDate: '15/05/2023',
    whatsappNumber:  '41 99860 9093',
    CNPJ: '39.456.281/0001-27',
    CNPJaddress: 'R. Imac. Conceição, 1430 - Prado Velho, Curitiba - PR, 80215-182',
    aboutUs: {
        title: 'Quem somos',
        content: [
            'Nosso objetivo: entregar a melhor experiência de compra de autopeças',
            'Com a mais avançada inteligência de compatibilidade de autopeças do Brasil, você compra o produto certo para o seu veículo com tranquilidade.'
        ],
        link: 'https://paraempresas.goparts.com.br/',
        linkCta: 'GoParts para empresas',
    },
    socialMedia: {
        Instagram: 'https://www.instagram.com/gopartsbrasil/',
        Facebook: 'https://www.facebook.com/gopartsbrasil',
        LinkedIn: 'https://www.linkedin.com/company/78339440/admin/dashboard/',
        Youtube: '',
        Twitter: '',
    },
}

export const CardOperators = [
    Master,
    Visa,
    Amex,
    Hipercard,
    Jcb,
    Elo,
    Alelo,
    Diners,
    Pix,
];

export const userData = {
    cpf: '07525037069',
    phone: '41997507912',
    defaultVehiclePlate: 'amf0758',
    defaultVehicleChassis: '12345678912345678',
}

export const UserAddresses = [
    {
        name: 'Casa',
        street: 'Rua Chico Xavier',
        streetNumber: '302',
        neighborhood: 'Centro',
        city: 'Curitiba',
        uf: 'PR',
        cep: '80300100',
        isDefault: true,
    },
    {
        name: 'Trabalho',
        street: 'Av Cândido de Abreu, 234',
        streetNumber: 'Bloco 1 - Sala 2',
        neighborhood: 'Batel',
        city: 'Curitiba',
        uf: 'PR',
        cep: '80050200',
        isDefault: false,
    },
]

export const ShippingOptions = [
    {
        name: 'Jadlog',
        deliveryPrice: '31,30',
        deliveryTime: '3',
    },
    {
        name: 'J&T',
        deliveryPrice: '28,15',
        deliveryTime: '5'
    },
    {
        name: 'Retirar no local',
    },
]

export const userCreditCards = [
    // {
    //     cardNumber: '5162567812345678',
    //     validThru: '02/30',
    //     cardHolder: 'João da Silva',
    //     address: {
    //         street: 'Rua Chico Xavier',
    //         streetNumber: '302',
    //         neighborhood: 'Centro',
    //         city: 'Curitiba',
    //         uf: 'PR',
    //         cep: '80300100',
    //     },
    //     isDefault: true,
    // },
    // {
    //     cardNumber: '4000123456781234',
    //     validThru: '07/28',
    //     cardHolder: 'Maria de Souza',
    //     address: {
    //         street: 'Av Cândido de Abreu, 234',
    //         streetNumber: 'Bloco 1 - Sala 2',
    //         neighborhood: 'Batel',
    //         city: 'Curitiba',
    //         uf: 'PR',
    //         cep: '80050200',
    //     },
    //     isDefault: false,
    // },
];

export const validCoupons = [
    {
        coupon: '10PORCENTO',
        discountPrice: 0,
        discountPercent: 0.1,
        freeShipping: false,
    },
    {
        coupon: '10REAIS',
        discountPrice: 10,
        discountPercent: 0,
        freeShipping: false,
    },
    {
        coupon: 'FRETEGRATIS',
        discountPrice: 0,
        discountPercent: 0,
        freeShipping: true,
    },
]

export const cardOperatorLogos = {
    visa: Visa,
    mastercard: Master,
    amex: Amex,
    elo: Elo,
    alelo: Alelo,
    hipercard: Hipercard,
    diners: Diners,
    jcb: Jcb,
}

export const UfList = {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins'
  };

export const ClientPH = {
    maxParcelNumber: 6,
}

export const ProductPH = [
    {
        ds_number: '5U0807109C',
        description: 'Alma de para-choque Gol Saveiro Voyage Original',
        originalPrice: 1050.11,
        price: 427.66,
        images: [
            'https://storage.googleapis.com/pocrenault-daa71.appspot.com/zpicspub/a24ee053-0036-4fe9-b923-58817ea9db10.jpg',
            'https://storage.googleapis.com/pocrenault-daa71.appspot.com/zpicspub/00412789-b6d8-433c-9212-bceb147c48ba.jpg',
            'https://storage.googleapis.com/pocrenault-daa71.appspot.com/zpicspub/921c017a-1c1e-4cf7-8208-4a7ce84b1b3d.jpg',
            'https://storage.googleapis.com/pocrenault-daa71.appspot.com/zpicspub/a24ee053-0036-4fe9-b923-58817ea9db10.jpg',
            'https://storage.googleapis.com/pocrenault-daa71.appspot.com/zpicspub/00412789-b6d8-433c-9212-bceb147c48ba.jpg',
            'https://storage.googleapis.com/pocrenault-daa71.appspot.com/zpicspub/921c017a-1c1e-4cf7-8208-4a7ce84b1b3d.jpg',
        ],
        active: true,
    },
];

export const OrderPH = [
    {
        id: '008999',
        status: 'newOrder',
        date: '2024-04-11T20:02:13.353Z',
        address: 'Rua Professor Brasílio Ovídio da Costa 130 Portão Curitiba/PR 80320100',
        payment: {
            type: 'PIX',
            subtotalPrice: 1403.04,
            discount: 10.50,
            shipping: 33.90,
            totalPrice: 1439.04,
            pixCode: '00020101021226770014BR.GOV.BCB.PIX2555api.itau/pix/qr/v2/f3b43b80-f78c-4391-b8bb-8d36ce2e849b5204000053039865802BR5925ZOOP TECNOLOGIA E MEIOS D6009SAO PAULO62070503***6304FFDB',
        },
        items: [
            {
                id: '8200942408',
                paymentPrice: 121.80,
                qty: 3,
            },
            {
                id: '5U0807109C',
                paymentPrice: 427.66,
                qty: 1,
            },
            {
                id: '1J0412331C',
                paymentPrice: 73.61,
                qty: 2,
            },
        ]
    },
    {
        id: '008999',
        status: 'preparing',
        date: '2024-04-11T20:02:13.353Z',
        address: 'Rua Professor Brasílio Ovídio da Costa 130 Portão Curitiba/PR 80320100',
        payment: {
            type: 'Cartão de Crédito',
            parcels: 4,
            subtotalPrice: 1403.04,
            discount: 10.50,
            shipping: 33.90,
            totalPrice: 1439.04,
        },
        items: [
            {
                id: '5U0807109C',
                paymentPrice: 427.66,
                qty: 1,
            },
            {
                id: '1J0412331C',
                paymentPrice: 73.61,
                qty: 2,
            },
        ]
    },
    {
        id: '008999',
        status: 'canceled',
        date: '2024-04-11T20:02:13.353Z',
        address: 'Rua Professor Brasílio Ovídio da Costa 130 Portão Curitiba/PR 80320100',
        payment: {
            type: 'PIX',
            subtotalPrice: 1403.04,
            discount: 10.50,
            shipping: 33.90,
            totalPrice: 1439.04,
        },
        items: [
            {
                id: '5U0807109C',
                paymentPrice: 427.66,
                qty: 1,
            },
        ]
    },
    {
        id: '008999',
        status: 'transporting',
        date: '2024-04-11T20:02:13.353Z',
        address: 'Rua Professor Brasílio Ovídio da Costa 130 Portão Curitiba/PR 80320100',
        payment: {
            type: 'PIX',
            subtotalPrice: 1403.04,
            discount: 10.50,
            shipping: 33.90,
            totalPrice: 1439.04,
        },
        items: [
            {
                id: '5U0807109C',
                paymentPrice: 427.66,
                qty: 1,
            },
        ]
    },
    {
        id: '008999',
        status: 'delivered',
        date: '2024-04-11T20:02:13.353Z',
        address: 'Rua Professor Brasílio Ovídio da Costa 130 Portão Curitiba/PR 80320100',
        payment: {
            type: 'PIX',
            subtotalPrice: 1403.04,
            discount: 10.50,
            shipping: 33.90,
            totalPrice: 1439.04,
        },
        items: [
            {
                id: '5U0807109C',
                paymentPrice: 427.66,
                qty: 1,
            },
        ]
    },
];

export const SellerPH = [
    {
        name: 'Concessionária Volkswagen - Goiânia GO',
        logo: 'volkswagen',
        originalPrice: 112.86,
        price: 94.69,
        stock: 20,
        originCEP: 80320100,
    },
    {
        name: 'Concessionária Audi - Goiânia GO',
        logo: 'audi',
        originalPrice: 122.86,
        price: 98.48,
        stock: 64,
        originCEP: 80320100,
    },
    {
        name: 'Concessionária Volkswagen - São Paulo SP',
        logo: 'volkswagen',
        originalPrice: 142.86,
        price: 103.23,
        stock: 12,
        originCEP: 80320100,
    },
]

// export const ModelsPH = [
//         'A1',
//         'A2',
//         'A3',
//         'A4',
//         'A5 Sportback',
//         'R8',
//         'Q3',
//         'Q5',
//         'Q7',
//         'RSQ3',
//         'TT',
//         'TTRS',
//         'TTS',
// ]

export const ModelsPH = {
    audi: [
        'A1',
        'A2',
        'A3',
        'A4',
        'A5 Sportback',
        'R8 A5 Sportback Teste Carro com nome Enorme 2.0 power master',
        'Q3',
        'Q5',
        'Q7',
        'RSQ3',
        'TT',
        'TTRS',
        'TTS',
    ],
    bmw: [
        '120',
        '320',
        'M3',
        'X3',
        'X4',
        '330',
    ]
}

export const VersionsPH = [
    '1.0 turbo',
    '1.5 turbo',
    '1.6 AWD',
    '2.0',
    '2.0 AWD',
    '2.0 Diesel',
    '2.4 V6',
    '2.4 V6 turbo',
]

export const includedBrands = [
    "audi",
    "bmw",
    "byd",
    "chery",
    "chevrolet",
    "citroen",
    "fiat",
    "ford",
    "harley-davidson",
    "honda",
    "honda motos",
    "hyundai",
    "jeep",
    "kia",
    "mercedes-benz",
    "mini",
    "mitsubishi",
    "nissan",
    "peugeot",
    "renault",
    "toyota",
    "volkswagen",
    "volvo",
    "yamaha",
];

export const homologBrands = [
    'motorcraft',
    'motrio',
    'acdelco',
    'mopar',
    'bproauto'
]

export const CategoriesPH = configData.defaultStore === 'UmuaramaHD' 
? 
    {
        // 'Boutique': Boutique,
        // 'Peças': Pecas,
        // 'Acessórios': Acessorios,
        'Filtros': FiltrosImg,
        'Freios': FreiosImg,
        'Suspensão & Direção': SuspensaoDirecaoImg,
        'Motor & Periféricos': MotorPerifericosImg,
        'Arrefecimento': ArrefecimentoImg,
        'Ignição & Elétrica': IgnicaoEletricaImg,
        'Correias & Tensores & Rolamentos': CorreiasTensoresRolamentosImg,
        'Sistemas de Alimentação & Combustivel': SistemasAlimentacaoCombustivelImg,
        'Ar Condicionado': ArCondicionadoImg,
        'Transmissão & Embreagens': TransmissaoEmbreagensImg,
        'Coxins e Suportes': CoxinsSuportesImg,
        'Lubrificantes e Fluidos': LubrificantesFluidosImg,
        'Carroceria': CarroceriaImg,
    }
:
    {
        'Filtros': FiltrosImg,
        'Freios': FreiosImg,
        'Suspensão & Direção': SuspensaoDirecaoImg,
        'Motor & Periféricos': MotorPerifericosImg,
        'Arrefecimento': ArrefecimentoImg,
        'Ignição & Elétrica': IgnicaoEletricaImg,
        'Correias & Tensores & Rolamentos': CorreiasTensoresRolamentosImg,
        'Sistemas de Alimentação & Combustivel': SistemasAlimentacaoCombustivelImg,
        'Ar Condicionado': ArCondicionadoImg,
        'Transmissão & Embreagens': TransmissaoEmbreagensImg,
        'Coxins e Suportes': CoxinsSuportesImg,
        'Lubrificantes e Fluidos': LubrificantesFluidosImg,
        'Carroceria': CarroceriaImg,
        'Iluminação': IluminacaoImg,
        // Uncomment these if needed
        // 'Kits de Revisão & Reparo': KitsRevisaoReparoImg
};

export const productReview = [
    {
        score: 5,
        comment: 'Muito bom esse produto, chegou rápido e é 100% original.'
    },
    {
        score: 5,
        comment: 'Peça genuína Harley Davidson e atendimento excelente'
    },
    {
        score: 2,
        comment: 'Muito ruim, demorou 20 dias pra chegar a peça!'
    },
    {
        score: 4,
        comment: 'Demorou pra chegar, mas fora isso a peça é original de qualidade!'
    },
    {
        score: 5
    },
    {
        score: 4,
    },
    {
        score: 5,
    }
];