import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import GPUser from '../models/gpuser'; // Correct import for default export



const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

let userCreationPromise = null; // Global promise to track user creation


export const UserProvider = ({ children }) => {
  const [gpuser, setUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true); // New loading state
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const gpUser = new GPUser({ id: authUser.uid, email: authUser.email });
        const userDocRef = gpUser.firestoreRef;
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const loadedUser = await GPUser.fromDocument(userDoc);
          setUser(loadedUser);
        } else {
          // Handle case where the user document doesn't exist
        }
      }
      setIsUserLoading(false); // Set loading to false once auth state is determined
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <UserContext.Provider value={{ gpuser, setUser, isUserLoading }}>
      {children}
    </UserContext.Provider>
  );
};