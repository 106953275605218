import React, { useState, useEffect } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import '../../App.css';
import '../../Home/Home.css';
import './Slider.css';
import './ProductSlider.css';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import ProductTile from '../Tiles/ProductTile';
import Button from '../Button';
import { ProductPH } from '../../Utilities/Placeholders';
import { ArrowRightAlt } from '@mui/icons-material';

function ProductSlider() {
    const calculateSlidesPerPage = () => {
        return window.innerWidth > 1280
            ? 4
            : window.innerWidth > 640
                ? Math.floor(window.innerWidth / 320)
                : 1
    };

    // Initialize slidesPerPage state using the helper function
    const [slidesPerPage, setSlidesPerPage] = useState(calculateSlidesPerPage);

    useEffect(() => {
        function handleResize() {
            // Update slidesPerPage based on the new window width using the same helper function
            setSlidesPerPage(calculateSlidesPerPage());
        }

        // Add resize event listener
        window.addEventListener('resize', handleResize);
        
    }); // Empty dependency array ensures this effect runs only once on mount

    const itemsPerBatch = slidesPerPage;
    const chunkArray = (array, size) => {
        const chunked = [];
        for (let i = 0; i < array.length; i += size) {
            chunked.push(array.slice(i, i + size));
        }
        return chunked;
    }

    const chunkedProducts = chunkArray(ProductPH, itemsPerBatch);

    function useDebouncedResize(callback, delay) {
        useEffect(() => {
            const debouncedHandleResize = debounce(() => {
                callback();
            }, delay);
    
            window.addEventListener('resize', debouncedHandleResize);
    
            return () => {
                window.removeEventListener('resize', debouncedHandleResize);
            };
        }, [callback, delay]);
    }
    
    function debounce(func, wait, immediate) {
        let timeout;
        return function executedFunction() {
            const later = function() {
                timeout = null;
                if (!immediate) func();
            };
    
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func();
        };
    }
    
    // Usage inside your ProductSlider component
    useDebouncedResize(() => setSlidesPerPage(calculateSlidesPerPage()), 250);

    return (
        <div className='productSliderSection'>
            <Swiper
                className='productSliderContainer'
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={1}
                spaceBetween={1000}
                // slidesPerView={
                //     (window.innerWidth > 600
                //         ? 'auto'
                //         : 1
                //     )
                // }
                navigation={true}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => ''}
                onSlideChange={() => ''}
            >
                {chunkedProducts.map((chunk, index) => (
                    <SwiperSlide key={index} className='sliderRow'>
                        {chunk.map(product => (
                            <ProductTile
                                key={product.id}
                                id={product.id}
                                title={product.title}
                                description={product.description}
                                originalPrice={product.originalPrice}
                                price={product.price}
                                img={product.img}
                            />
                        ))}
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
}

export default ProductSlider;