import { combineReducers } from 'redux';
import cart from './cart';
import vehicle from './vehicle';
import user from './user';

const rootReducer = combineReducers({
    cart,
    vehicle,
    user
});

export default rootReducer;