import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { functions } from '../firebase'; // Ensure the correct path to your firebase.js
import { httpsCallable } from 'firebase/functions';


const getAddressCF = httpsCallable(functions, 'getCepInfoEx');


export async function getAddress(cep) {
    try {
        const result = await getAddressCF({
            "cep": cep,
        });
        if (!result.data) {
            throw new Error('CEP não encontrado');
        }
        localStorage.setItem('userCep', cep);
        return { success: true, data: result.data };
    } catch (err) {
        console.error('Error calling getAddress:', err);
        return { success: false, error: 'CEP não encontrado' };
    }
};

