// Function to import all images from a given context
function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
      // Remove './' prefix and '.png' suffix from the file name
      const fileName = item.replace('./', '').replace('.png', '');
      images[fileName] = r(item);
  });
  return images;
}

// Import all PNG images from the specified directory
const imagesContext = require.context('../images/models/', false, /\.png$/);
export const ModelImgs = importAll(imagesContext);