import React, { useState, useEffect } from 'react';
import './Dialog.css';
import { YearList } from '../Utilities/YearList';
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';

function Dialog(props) {
    const navigate = useNavigate();
    const [selectedYear, setSelectedYear] = useState(null);

    useEffect(() => {
        // Only navigate if selectedYear is not null
        if (selectedYear) {
            navigate(`/${props.brand}/${selectedYear}/modelos`);
            props.closeOnClick();
        }
    }, [selectedYear]); // Adding selectedYear as a dependency

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value); // This updates selectedYear and triggers a re-render
    };

    return (
        <div className='dialogBackground'>
            <div className='card dialogContainer'>
                <div className='dialogCloseButton' onClick={props.closeOnClick}><CloseIcon/></div>
                {props.type === 'selectYear' && 
                    <>
                        <img src={props.brandLogo} className='dialogImg' alt="Brand Logo"/>
                        <div className='column center' style={{gap: '10px'}}>
                            <h3>Selecione o ano do veículo:</h3>
                            <select id='yearSelect' value={selectedYear} onChange={handleYearChange}>
                                {YearList.map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default Dialog;