import React from 'react';
import Button from '../Common/Button';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { configData } from '../Whitelabel/websitesData';

function Privacy() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1);
    };

    return (
        <div className='main fadeIn'>
            <div className='pageContent'>
                <div className='card'>
                    <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                        <h2>Política de Privacidade</h2>
                        <p>A {configData.defaultStore} disponibilizou o aplicativo {configData.defaultStore} de forma gratuita. Este SERVIÇO é fornecido pela {configData.defaultStore} sem custos e deve ser utilizado no estado em que se encontra.</p>
                        <p>Esta página é usada para informar os visitantes sobre nossas políticas de coleta, uso e divulgação de Informações Pessoais, caso decida usar nosso Serviço.</p>
                        <p>Se você optar por usar nosso Serviço, você concorda com a coleta e uso de informações em relação a esta política. As informações pessoais que coletamos são usadas para fornecer e melhorar o serviço. Não usaremos ou compartilharemos suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade.</p>
                        <p>Os termos usados nesta Política de Privacidade têm os mesmos significados que em nossos Termos e Condições, que podem ser acessados em <a className="textLink" href={`https://${configData.defaultStoreLink}`} target='blank'>{configData.defaultStoreLink}</a>, a menos que definido de outra forma nesta Política de Privacidade.</p>

                        <h3>Coleta e uso de informações</h3>
                        <p>Para uma melhor experiência, ao usar nosso Serviço, podemos exigir que você nos forneça certas informações de identificação pessoal. As informações que solicitamos serão retidas por nós e usadas conforme descrito nesta Política de Privacidade.</p>
                        <p>O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo.</p>
                        <p>Links para a Política de Privacidade de provedores de serviços terceirizados usados pelo aplicativo:</p>
                        <p><a className="textLink" href="https://www.google.com/policies/privacy/" target="_blank">• Google Play Services</a></p>
                        <p><a className="textLink" href="https://firebase.google.com/policies/analytics" target="_blank">• Google Analytics for Firebase</a></p>
                        <p><a className="textLink" href="https://firebase.google.com/support/privacy/" target="_blank">• Firebase Crashlytics</a></p>
                        <p><a className="textLink" href="https://www.facebook.com/about/privacy/update/printable" target="_blank">• Facebook</a></p>

                        <h3>Log Data</h3>
                        <p>Queremos informar que sempre que você utiliza nosso Serviço, em caso de erro no aplicativo, coletamos dados e informações (por meio de produtos de terceiros) em seu telefone denominado Log Data. Esses dados de registro podem incluir informações como endereço de protocolo de Internet ("IP") do dispositivo, nome do dispositivo, versão do sistema operacional, configuração do aplicativo ao utilizar nosso serviço, hora e data de uso do serviço e outras estatísticas.</p>

                        <h3>Cookies</h3>
                        <p>Cookies são arquivos com uma pequena quantidade de dados que são comumente usados como identificadores exclusivos anônimos. Eles são enviados para o seu navegador a partir dos sites que você visita e são armazenados na memória interna do seu dispositivo.</p>
                        <p>Este Serviço não usa esses “cookies” explicitamente. No entanto, o aplicativo pode usar código de terceiros e bibliotecas que usam “cookies” para coletar informações e melhorar seus serviços. Você tem a opção de aceitar ou recusar esses cookies e saber quando um cookie está sendo enviado para o seu dispositivo. Se você optar por recusar nossos cookies, poderá não ser capaz de usar algumas partes deste Serviço.</p>

                        <h3>Provedores de serviço</h3>
                        <p>Podemos empregar empresas terceirizadas e indivíduos pelos seguintes motivos:</p>
                        <p>• Para facilitar nosso Serviço;</p>
                        <p>• Para fornecer o Serviço em nosso nome;</p>
                        <p>• Para realizar operações relacionadas ao Serviço; ou</p>
                        <p>• Para nos ajudar a analisar como nosso Serviço é usado.</p>
                        <p>Queremos informar aos usuários deste Serviço que esses terceiros têm acesso às suas Informações Pessoais. O motivo é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações obtidas para qualquer outra finalidade.</p>

                        <h3>Segurança</h3>
                        <p>Valorizamos sua confiança em nos fornecer suas informações pessoais, portanto, estamos nos empenhando para usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se que nenhum método de transmissão pela internet, ou método de armazenamento eletrônico, é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.</p>

                        <h3>Links para outros sites</h3>
                        <p>Este serviço pode conter links para outros sites. Se você clicar em um link de terceiros, será direcionado a esse site. Observe que esses sites externos não são operados por nós. Portanto, recomendamos enfaticamente que você analise a Política de Privacidade desses sites. Não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.</p>

                        <h3>Privacidade das crianças</h3>
                        <p>Esses Serviços não se dirigem a pessoas com menos de 14 anos. Não coletamos intencionalmente informações de identificação pessoal de crianças com menos de 14 anos. No caso de descobrirmos que uma criança menor de 14 anos nos forneceu informações pessoais, nós as apagamos imediatamente de nossos servidores. Se você é um pai ou responsável e sabe que seu filho nos forneceu informações pessoais, entre em contato para que possamos tomar as medidas necessárias.</p>

                        <h3>Mudanças nesta Política de Privacidade</h3>
                        <p>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Portanto, é recomendável revisar esta página periodicamente para verificar quaisquer alterações. Iremos notificá-lo de quaisquer alterações, publicando a nova Política de Privacidade nesta página.</p>
                        <p>Esta política está em vigor a partir de {configData.launchDate}.</p>

                        <h3>Contate-Nos</h3>
                        <p>Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite em nos contatar em <a href={`mailto:${configData.defaultContactEmail}`} target='blank'>{configData.defaultContactEmail}</a>.</p>
                        <Button
                            className='buttonColorSecond smallButton'
                            label='voltar'
                            onClick={handleGoBack}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Privacy;