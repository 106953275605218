import { includedBrands, homologBrands } from "./Placeholders";

// Normalize included brands for consistent comparison
const normalizedIncludedBrands = includedBrands.map(brand => brand.toLowerCase());
const normalizedIncludedHomolobBrands = homologBrands.map(brand => brand.toLowerCase());

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    const fileName = item.replace('./', '').replace(/\.(jpg|svg)$/, '');
    images[fileName] = r(item);
  });
  return images;
}

// Import JPEGs
const BrandsLogos = importAll(require.context('../images/brands/', false, /\.jpg$/));
// Import SVGs
const BrandsSVGs = importAll(require.context('../images/brands/', false, /\.svg$/));

// List of specific SVG files to include
const homologBrandsList = ['acdelco', 'bproauto', 'mopar', 'motorcraft', 'motrio'];

// Function to create a list of specified SVGs
export const HomologBrandsSVG = homologBrandsList.reduce((acc, key) => {
  if (normalizedIncludedHomolobBrands.includes(key.toLowerCase())) {
    const brandName = key.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
    acc[brandName] = BrandsSVGs[key];
  }
  return acc;
}, {});

// Create a list of brands with their JPEG logos
export const BrandsList = Object.keys(BrandsLogos).reduce((acc, key) => {
  if (normalizedIncludedBrands.includes(key.toLowerCase())) {
    const brandName = key.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
    acc[brandName] = BrandsLogos[key];  // Directly assign JPEG
  }
  return acc;
}, {});

// Create a separate list for SVGs
export const BrandsListSVG = Object.keys(BrandsSVGs).reduce((acc, key) => {
  if (normalizedIncludedBrands.includes(key.toLowerCase())) {
    const brandName = key.split('-').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
    acc[brandName] = BrandsSVGs[key];  // Directly assign SVG
  }
  return acc;
}, {});

export const BrandCodes = {
  "alfa-romeo": 2,
  "byd": 9910,
  "honda": 45,
  "honda motos": 999,
  "mclaren": 1518,
  "asia-motors": 879,
  "hummer": 1506,
  "mercedes-benz": 74,
  "aston-martin": 881,
  "hyundai": 183,
  "mercury": 161,
  "audi": 5,
  "infiniti": 1526,
  "mg": 75,
  "bmw": 16,
  "isuzu": 54,
  "mini": 1523,
  "bugatti": 788,
  "jac": 2873,
  "mitsubishi": 77,
  "chana": 4609,
  "jaguar": 56,
  "nissan": 80,
  "changan": 2852,
  "jeep": 882,
  "pagani": 4164,
  "chery": 2887,
  "kia": 184,
  "peugeot": 88,
  "chevrolet": 138,
  "koenigsegg": 4163,
  "porsche": 92,
  "chrysler": 20,
  "lada": 63,
  "puma": 880,
  "citroen": 21,
  "lamborghini": 701,
  "renault": 93,
  "daihatsu": 25,
  "lancia": 64,
  "seat": 104,
  "delorean": 1494,
  "land-rover": 1820,
  "ssangyong": 175,
  "dodge": 29,
  "lexus": 842,
  "subaru": 107,
  "engesa": 4541,
  "lifan": 3086,
  "suzuki": 109,
  "fiat": 35,
  "lincoln": 1200,
  "tesla": 3328,
  "ford": 36,
  "lobini": 3679,
  "toyota": 111,
  "geely": 2590,
  "mahindra": 1280,
  "troller": 3681,
  "gurgel": 2249,
  "maserati": 771,
  "volkswagen": 121,
  "hafei": 2866,
  "mazda": 72,
  "volvo": 120,
}