import mainLogo from '../images/UmuaramaHD/UHD_logo.webp';
import squareLogo from '../images/UmuaramaHD/UHD_logo_square.webp';

export const configData = {
    defaultStore: 'UmuaramaHD',
    defaultStoreLink: 'loja.umuaramahd.com.br',
    launchDate: '15/05/2023',
    defaultContactEmail: 'ecommerce@umuaramahd.com.br',
    whatsappNumber: '62 99936 2311',
    CNPJ: '13.304.379/0001-75',
    CNPJaddress: 'R. S-1, 35 Loja - Bairro St. Bela Vista, Goiânia - GO | CEP: 74823-420',

    searchByPlate: true,

    coupons: [
        // {
        //     coupon: '10PORCENTO',
        //     discountPrice: 0,
        //     discountPercent: 0.1,
        //     freeShipping: false,
        // },
        // {
        //     coupon: '10REAIS',
        //     discountPrice: 10,
        //     discountPercent: 0,
        //     freeShipping: false,
        // },
        // {
        //     coupon: 'FRETEGRATIS',
        //     discountPrice: 0,
        //     discountPercent: 0,
        //     freeShipping: true,
        // },
    ],

    brands: [
        // 'audi',
        // 'bmw',
        // 'chery',
        // 'chevrolet',
        // 'citroen',
        // 'fiat',
        // 'ford',
        'harley-davidson',
        // 'honda',
        // 'honda motos',
        // 'hyundai',
        // 'jeep',
        // 'kia',
        // 'mercedes-benz',
        // 'mini',
        // 'mitsubishi',
        // 'nissan',
        // 'peugeot',
        // 'renault',
        // 'toyota',
        // 'volkswagen',
        // 'volvo',
        // 'yamaha',
    ],

    homologBrands: [
        // 'motorcraft',
        // 'motrio',
        // 'acdelco',
        // 'mopar',
        // 'bproauto',
    ],

    storeSellersWarehouses: [
        'UMUARAMA HARLEY GOIANIA',
    ],

    endYear: 2023,
    startYear: 1996,

    payment: {
        maxParcels: 10,
        checkoutParcels: {
            0: 1,
            200: 2,
            300: 3,
            400: 4,
            500: 5,
            600: 6,
            700: 7,
            800: 8,
            900: 9,
            1000: 10
        }
    },
}

export const contentData = {
    mainLogo: mainLogo,
    squareLogo: squareLogo,

    aboutUs: {
        title: 'Quem somos',
        content: [
            'Foi em 1993, que a primeira concessionária Umuarama surgiu na cidade de Araguaína, Tocantins, com a bandeira Fiat. O novo negócio revolucionou o mercado local, que refletia os tempos áureos do recém lançado Uno Mille (1990), que apesar de não ser tão potente, caiu nas graças do público pela boa relação custo/benefício e pelo baixo consumo.',
            'Em 2005, a segunda concessionária Umuarama chegou em Araguaína, levando a sofisticação e imponência da Toyota. Naquela época, o Corolla, que tinha Brad Pitt como garoto propaganda e INXS na trilha do comercial, consolidou-se como o maior sucesso de vendas da marca no Brasi. Este passo foi um marco evolutivo para a Umuarama que ampliou sua atuação, conquistando espaço nos estados do Maranhão e Pará. Foi em 2011 que a Umuarama Fiat também chegou na cidade de Redenção no Pará e, em 2013, na cidade de Parauapebas.',
            'Em 2019, novos negócios foram incorporados às concessionárias, levando um pacote completo de soluções e serviços para que todos os clientes pudessem vivenciar a melhor experiência ao escolher o seu novo carro ou moto: surgiu assim as áreas de Carros seminovos, Consórcios e Seguros. Hoje, são mais de 22 concessionárias em 5 estados brasileiros – Pará, Maranhão, Tocantins, Goiás e Minas Gerais -, representando grandes marcas do mercado automobilístico mundial: Toyota, Fiat, Volkswagen, Kia, Citroën, Harley-Davidson e KTM.',
        ],
        link: 'https://goias.umuaramaharleydavidson.com.br/quem-somos/',
        linkCta: 'Saiba mais',
    },

    socialMedia: {
        Instagram: 'https://www.instagram.com/umuaramaharley/',
        Facebook: 'https://www.facebook.com/umuarama.harleydavidson?paipv=0&eav=Afa0hek-Ed663_4ZZhmR4nBDtRMiK90LXdpheFzEKFYYNDrXc46OfltGQCvLbz9ulKk&_rdr',
        LinkedIn: '',
        Youtube: '',
        Twitter: '',
    },

    footerContent: [
        ''
    ],
}