import React, { useState, useEffect } from 'react';
import '../App.css';
import Button from '../Common/Button';

function CookieBar() {
    const [visibleCookieBar, setVisibleCookieBar] = useState(false); // Initially false for animation
    const [acceptedCookies, setAcceptedCookies] = useState(() => localStorage.getItem('acceptedCookies') === 'true');

    // useEffect to trigger visibility after 0.5s on page load
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisibleCookieBar(true); // Set to true after 0.5s
        }, 1000);

        return () => clearTimeout(timer); // Clear timeout if component unmounts
    }, []); // Empty dependency array ensures it runs only on mount

    const acceptCookieClick = () => {
        //console.log('cookie click!');
        setVisibleCookieBar(false); // Hide the cookie bar immediately
        
        // Delay setting the cookie acceptance by 500ms
        setTimeout(() => {
            setAcceptedCookies(true);
            localStorage.setItem('acceptedCookies', 'true'); // Save cookie acceptance in localStorage
        }, 500);
    };

    return (
        <>
            {!acceptedCookies &&
                <div className={`cookieBarContainer ${!visibleCookieBar ? 'hiddenCookieBar' : 'visibleCookieBar'}`}>
                    <div className='cookieBarColumn'>
                        <span>Cookies nos ajudam a entregar nossos serviços. Ao usar nossos serviços, você concorda com a <a href='/privacidade'>Política de Privacidade</a></span>
                        <Button
                            className='buttonColorThird smallButton'
                            label='Aceitar e Continuar'
                            onClick={acceptCookieClick}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default CookieBar;