import React from 'react';
import '../App.css';
import { ModelsPH } from '../Utilities/Placeholders';
import ModelTile from '../Common/Tiles/ModelTile';
import VehicleSelect from '../Common/VehicleSelect';
import { useParams } from 'react-router-dom';

function ModelSelect(props) {
    const { brand, year } = useParams(); 
    const Models = ModelsPH[brand.toLowerCase()]
    const modelTileClick = () => {
        //console.log('model clicked');
    };
    return (
        <div className='main fadeIn'>
            <VehicleSelect
                brand={brand}
                // year={year}
            />
            <div className='pageContent' style={{marginTop: 'var(--headerHeight)'}}>
                <div className='centerGrid'>
                    <div className='tileGrid'>
                        {Models && Models.map(model => (
                            <ModelTile
                                name={model}
                                brand={brand}
                                onClick={modelTileClick}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModelSelect;