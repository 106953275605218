import React, { useEffect, useState } from 'react';
import '../App.css';
import './HeaderNavigation.css';
import { auth } from '../firebase';
import { useSelector } from 'react-redux';
import Button from '../Common/Button';
import { Home, Login, Menu, Close } from '@mui/icons-material';
import CartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import OrdersIcon from "@mui/icons-material/List";
import EnterIcon from "@mui/icons-material/Login";
import ExitIcon from "@mui/icons-material/Logout";
import { useNavigate } from 'react-router-dom';


function SideAccountMenu({ isOpen, closeButton }) {
  const navigate = useNavigate();

  const cartItems = useSelector(state => state.cart.carrinho);

  const [user, setUser] = useState(null);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(isOpen);

  const handleUserAccount = async () => {
    try {
      auth.onAuthStateChanged((user) => {
        setUser(user);
      });
    } catch (error) {
      console.error('Erro ao obter usuário:', error.message);
    }
  };

  const navCart = () => {
    navigate('/checkout');
    closeButton();
  }
  const navOrders = () => {
    navigate('/minha-conta#pedidos');
    closeButton();
  }
  const navMyAccount = () => {
    navigate('/minha-conta#meus-dados');
    closeButton();
  }

  useEffect(() => {
    handleUserAccount();
  }, []);

  const logout = async () => {
    closeButton();
    const isConfirmed = window.confirm(`Deseja sair da conta ${user.email}?`);
  
    if (isConfirmed) {
      try {
        await auth.signOut();
        navigate('/');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const login = () => {
    closeButton();
    navigate('/login');
  }

  useEffect(() => {
    handleUserAccount();
  }, []);

  return (
      <div className={`sideAccountMenuContainer ${isOpen ? 'opened' : ''}`}>
        <div className='sideMenuColumn'>
          {/* <a href='/checkout' className='buttonStyle buttonColorTransparentLight'>Carrinho</a>
          <a href='/pedidos' className='buttonStyle buttonColorTransparentLight'>Pedidos</a>
          <a href='/minha-conta' className='buttonStyle buttonColorTransparentLight'>Minha Conta</a>
          <a className='buttonStyle buttonColorTransparentLight'>Logout</a> */}
          <div className='sideMenuCloseButton'>
            <Button
              className='buttonColorTransparentLight largeText'
              iconStart={<Close style={{color: 'var(--grayIcon)'}}/>}
              onClick={() => closeButton()}
            />
          </div>
          {user &&
            <span style={{color: 'var(--mainContent)'}}>Olá, {user.email}</span>
          }
          <div style={{position: 'relative'}}>
            {cartItems.length > 0 &&
              <div className='cartItemCounterSideMenu fadeIn'>{cartItems.length}</div>
            }
            <Button
              className='buttonColorTransparentLight largeText'
              label='Carrinho'
              iconStart={<CartIcon/>}
              onClick={navCart}
            />
          </div>
          
          {user
            ?
              <>
                <Button
                  className='buttonColorTransparentLight largeText'
                  label='Pedidos'
                  iconStart={<OrdersIcon/>}
                  onClick={navOrders}
                />
                <Button
                  className='buttonColorTransparentLight largeText'
                  label='Minha Conta'
                  iconStart={<Home/>}
                  onClick={navMyAccount}
                />
                <Button
                  className='buttonColorTransparentLight largeText'
                  label='Sair'
                  iconStart={<ExitIcon/>}
                  onClick={logout}
                />
              </>
            :
              <Button
                className='buttonColorTransparentLight largeText'
                label='Fazer login'
                iconStart={<EnterIcon/>}
                onClick={login}
              />
          }
        </div>
      </div>
  );
}

export default SideAccountMenu;