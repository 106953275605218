import React from 'react';
import '../Dialog.css';
import './ModelTile.css'
import GenericVehicle from '../../images/0.png'

function ModelTile(props) {
    return (
        <div className='modelTileContainer' onClick={props.onClick}>
            <img src={GenericVehicle} className='modelImg'/>
            <div className='column center'>
                <h3 className='grayedText'>{props.brand}</h3>
                <h2>{props.name}</h2>
            </div>
        </div>
    );
}

export default ModelTile;