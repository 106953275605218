import React, { useEffect } from 'react';
import { setIsSelectingVehicle } from '../reducers/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import { handleWhatsappClick } from '../Utilities/Whatsapp';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { AddShoppingCart } from '@mui/icons-material';

function FloatingButton() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion } = useSelector(state => state.vehicle);

    const handleClick = () => {
        handleWhatsappClick(selectedBrand, selectedModel, selectedYear, selectedVersion);
    };

    const openVehicleSelect = () => {
        if (selectedVersion) {
            navigate('/categorias');
        } else {
            dispatch(setIsSelectingVehicle(!isSelectingVehicle));
        }
    };

    return (
        <div className='floatingContainer fadeIn'>
            <div className='floatingButton' style={{backgroundColor: 'var(--highlightGreen)', color: 'white'}} onClick={handleClick}>
                <WhatsApp style={{fontSize: '32px'}}/>
            </div>
            {/* {location.pathname !== '/checkout' && (
                <div className='floatingButton' style={{backgroundColor: 'var(--secondColor)', color: 'white'}} onClick={openVehicleSelect}>
                    <AddShoppingCart style={{fontSize: '28px'}}/>
                </div>
            )} */}
        </div>
    );
}

export default FloatingButton;