import React from 'react';
import Button from '../Common/Button';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { configData } from '../Whitelabel/websitesData';

function TermsAndConditions() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1);
    };

    return (
        <div className='main fadeIn'>
            <div className='pageContent'>
                <div className='card'>
                    <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                        <h2>Termos e Condições</h2>
                        <p>Ao baixar ou usar o aplicativo, estes termos se aplicarão automaticamente a você - portanto, certifique-se de lê-los com atenção antes de usar o aplicativo. Você não tem permissão para copiar ou modificar o aplicativo, qualquer parte do aplicativo ou nossas marcas registradas de forma alguma. Você não tem permissão para tentar extrair o código-fonte do aplicativo e também não deve tentar traduzir o aplicativo para outros idiomas ou fazer versões derivadas. O aplicativo em si, e todas as marcas, direitos autorais, direitos de banco de dados e outros direitos de propriedade intelectual relacionados a ele, pertencem à {configData.defaultStore}.</p>
                        <p>A {configData.defaultStore} está empenhada em garantir que a aplicação seja o mais útil e eficiente possível. Por esse motivo, nos reservamos o direito de fazer alterações no aplicativo ou cobrar por seus serviços, a qualquer momento e por qualquer motivo. Nunca cobraremos de você pelo aplicativo ou seus serviços sem previamente deixar bem claro exatamente o que você está pagando.</p>
                        <p>O aplicativo {configData.defaultStore} armazena e processa dados pessoais que você nos forneceu, a fim de fornecer nosso Serviço. É sua responsabilidade manter o seu telefone e o acesso ao aplicativo seguros. Portanto, recomendamos que você não faça jailbreak ou root em seu telefone, que é o processo de remoção de restrições e limitações de software impostas pelo sistema operacional oficial de seu dispositivo. Isso pode tornar seu telefone vulnerável a malwares / vírus / programas maliciosos, comprometer os recursos de segurança do telefone e pode significar que o aplicativo {configData.defaultStore} não funcionará corretamente.</p>
                        <p>O aplicativo usa serviços de terceiros que declaram seus próprios Termos e Condições. Link para os Termos e Condições de provedores de serviços terceirizados usados pelo aplicativo:</p>
                        <p><a className="textLink" href="https://www.google.com/policies/privacy/" target="_blank">• Google Play Services</a></p>
                        <p><a className="textLink" href="https://firebase.google.com/policies/analytics" target="_blank">• Google Analytics for Firebase</a></p>
                        <p><a className="textLink" href="https://firebase.google.com/support/privacy/" target="_blank">• Firebase Crashlytics</a></p>
                        <p><a className="textLink" href="https://www.facebook.com/about/privacy/update/printable" target="_blank">• Facebook</a></p>
                        <p>Esteja ciente de que existem certas coisas pelas quais a {configData.defaultStore} não se responsabiliza. Algumas funções do aplicativo exigirão que o dispositivo tenha uma conexão ativa com a Internet. A ligação pode ser Wi-Fi, ou fornecida pelo seu fornecedor de rede móvel, mas a {configData.defaultStore} não pode assumir a responsabilidade pelo aplicativo não operar em plena funcionalidade se não tiver acesso a Wi-Fi e não tiver mais sua cota de dados móveis disponível.</p>
                        <p>Se você estiver usando o aplicativo fora de uma área com Wi-Fi, lembre-se de que os termos do contrato com seu provedor de rede móvel ainda se aplicam. Como resultado, você pode ser cobrado por sua operadora de celular pelo custo dos dados durante a conexão ao acessar o aplicativo ou outras cobranças de terceiros. Ao usar o aplicativo, você aceita a responsabilidade por quaisquer cobranças, incluindo taxas de roaming de dados se usar o aplicativo fora de seu território (ou seja, região ou país) sem desligar o roaming de dados. Se você não for o pagador de contas do dispositivo no qual está usando o aplicativo, esteja ciente de que presumimos que você recebeu permissão do pagador de contas para usar o aplicativo.</p>
                        <p>A {configData.defaultStore} nem sempre pode se responsabilizar pela forma como você usa o aplicativo, ou seja, você precisa se certificar de que seu dispositivo fica carregado - se ficar sem bateria e você não conseguir ligá-lo para utilizar o Serviço, a {configData.defaultStore} não se responsabiliza.</p>
                        <p>No que diz respeito à responsabilidade da {configData.defaultStore} pelo uso que você faz do aplicativo, quando você estiver usando a {configData.defaultStore}, é importante ter em mente que embora nos esforcemos para que ele esteja sempre atualizado e correto, contamos que terceiros nos forneçam informações para que possamos disponibilizá-las a você. A {configData.defaultStore} não se responsabiliza por qualquer perda, direta ou indireta, que você experimente em decorrência de confiar totalmente nesta funcionalidade do aplicativo.</p>
                        <p>Em algum momento, podemos desejar atualizar o aplicativo. O aplicativo está disponível atualmente para Android e iOS - os requisitos para ambos os sistemas (e para quaisquer sistemas adicionais para os quais decidirmos estender a disponibilidade do aplicativo) podem mudar e você precisará baixar as atualizações se quiser continuar usando a aplicação. A {configData.defaultStore} não se compromete a atualizar sempre a aplicação para que seja relevante para si e / ou funcione com a versão Android e iOS instalada no seu dispositivo. No entanto, você se compromete a sempre aceitar as atualizações do aplicativo quando oferecidas a você. Também podemos desejar interromper o fornecimento do aplicativo e podemos encerrar o uso dele a qualquer momento sem aviso prévio para você. A menos que informemos o contrário, em caso de rescisão, (a) os direitos e licenças concedidos a você nestes termos serão encerrados; (b) você deve parar de usar o aplicativo e (se necessário) excluí-lo do dispositivo.</p>

                        <h2>Mudanças nestes Termos e Condições</h2>
                        <p>Podemos atualizar nossos Termos e Condições de tempos em tempos. Portanto, é recomendável revisar esta página periodicamente para verificar quaisquer alterações. Iremos notificá-lo de quaisquer alterações, publicando os novos Termos e Condições nesta página.</p>
                        <p>Estes termos e condições são válidos a partir de {configData.launchDate}.</p>

                        <h2>Contate-Nos</h2>
                        <p>Se você tiver alguma dúvida ou sugestão sobre os nossos Termos e Condições, não hesite em entrar em contato conosco em <a href={`mailto:${configData.defaultContactEmail}`} target='_blank'>{configData.defaultContactEmail}</a>.</p>
                        <Button
                            className='buttonColorSecond smallButton'
                            label='voltar'
                            onClick={handleGoBack}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
