import React from 'react';
import '../App.css';
import { Visibility, VisibilityOff } from "@mui/icons-material";

function PasswordToggle({ isVisible, onClick }) {
  return isVisible ? (
    <VisibilityOff className='buttonStyle buttonColorTransparentDark visibilityTogglePosition' style={{color: 'var(--grayIcon)'}} onClick={onClick} />
  ) : (
    <Visibility className='buttonStyle buttonColorTransparentDark visibilityTogglePosition'  style={{color: 'var(--grayIcon)'}} onClick={onClick} />
  );
}

export default PasswordToggle;