import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import '../App.css';

function Button(props) {
  const handleClick = () => {
    props.onClick(props.value);
  }
  const { navLink, onClick, className, iconStart, label, iconEnd, icon } = props;

  // Conditionally render a NavLink or a button based on the 'to' prop
  return navLink ? (
    <NavLink to={navLink} className={`buttonStyle ${className}`}>
      {iconStart && iconStart}
      {icon ? icon : <span>{label}</span>}
      {iconEnd && iconEnd}
    </NavLink>
  ) : (
    <button onClick={onClick} className={`buttonStyle ${className}`}>
      {iconStart && iconStart}
      {icon ? icon : <span>{label}</span>}
      {iconEnd && iconEnd}
    </button>
  );
}

Button.propTypes = {
  navLink: PropTypes.string, // Optional URL destination
  onClick: PropTypes.func, // Optional onClick function
  className: PropTypes.string, // Additional CSS classes
  iconStart: PropTypes.node, // Optional start icon
  iconEnd: PropTypes.node, // Optional end icon
  label: PropTypes.string, // Required button label
};

export default Button;