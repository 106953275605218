import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { setIsSelectingVehicle } from '../reducers/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../images/about_GoParts_bg_01.jpg';
import { storeInfo } from '../Utilities/Placeholders';
import Button from '../Common/Button';
import { contentData } from '../Whitelabel/websitesData';

function AboutUs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isSelectingVehicle, selectedVersion } = useSelector(state => state.vehicle);
    const { title, content, link } = contentData.aboutUs;

    const handleAboutUsClick = () => {
        window.open(`${link}`, '_blank');
    }

    const openVehicleSelect = () => {
        if (selectedVersion) {
          navigate('/categorias');
        } else {
          dispatch(setIsSelectingVehicle(!isSelectingVehicle));
        }
    };

    return (
        <div className='main fadeIn aboutUsBgStyle' style={{ backgroundImage: `url(${Background})` }}>
            <div className='column center' style={{ color: 'white', maxWidth: '1280px', margin: 'auto'}}>
                <div className='row'>
                    <div className='column aboutUsContainer'>
                        <h2>{title}</h2>
                        {content.map((text, index) => (
                            <span key={index}>{text}</span>
                        ))}
                        <div className='row' style={{gap: '10px'}}>
                            <Button
                                className='buttonColorSecond smallButton'
                                label={contentData.aboutUs.linkCta}
                                onClick={handleAboutUsClick}
                            />
                            <Button
                                className='buttonColorThird smallButton'
                                label='Comprar peças'
                                onClick={openVehicleSelect}
                            />
                        </div>
                    </div>
                    {!contentData.aboutUs.content.lenght > 2 &&
                        <div className='aboutUsEmpty'/>
                    }
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
