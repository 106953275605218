import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import './Header.css';
import { BrandsList } from '../Utilities/BrandsList';
import { CategoriesPH } from '../Utilities/Placeholders';
import { categoriesCars, categoriesBikes, bikeBrands } from '../Utilities/CategoriesLists';
import { subcatPeloNomeCategoria, subcatPeloNomeCategoriaBikes, idsSubcategoriaPeloNome, categoryAxle } from '../Utilities/CategoriesLists';
import { setIsSelectingVehicle, setSelectedBrand, setSelectedYear, setSelectedModel, setSelectedVersion } from '../reducers/vehicle'; // Import actions from vehicle.js
import Dialog from '../Common/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { loadOEbyCarCodeType, getCategoryWithIdProd, GET_PARTS_LIST_FROM_WH } from '../reducers/vehicle';
import { getFunctions, httpsCallable } from "firebase/functions";
import { defaultStore, storeSellersWarehouses } from '../Common/Breakpoints';
import Product from '../Common/Classes/Product';
import ProductTile from '../Common/Tiles/ProductTile';
import { CircularProgress } from '@mui/material';
import { configData } from  '../Whitelabel/websitesData';

function AutoComplete(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicle = useSelector(state => state.vehicle.selectedVehicle)
  const internalID = vehicle.InternalID;
  const searchValueLower = props.searchValue.toLowerCase();
  const allOEs = useSelector(state => state.vehicle.carOEs);
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    if((internalID !== undefined) && (internalID !== "")) dispatch(loadOEbyCarCodeType(internalID));
  }, [dispatch, internalID]);
  
  useEffect(() => {
    if(Object.keys(allOEs).length !== 0 && Object.keys(vehicle).length !== 0) allCarParts(allOEs)
    }, [allOEs]);
  
  const filteredAndSortedBrands = Object.keys(BrandsList)
  .filter(brand => {
    const brandLower = brand.toLowerCase();
    return brandLower.includes(searchValueLower) && 
    configData.brands.some(configBrand => configBrand.toLowerCase() === brandLower);
  })
  .sort((a, b) => {
    const startsWithA = a.toLowerCase().startsWith(searchValueLower);
    const startsWithB = b.toLowerCase().startsWith(searchValueLower);
    return startsWithA && !startsWithB ? -1 : !startsWithA && startsWithB ? 1 : a.toLowerCase().localeCompare(b.toLowerCase());
  });
  
  
  const [openedDialog, setOpenedDialog] = useState(false);
  // const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBrandLogo, setSelectedBrandLogo] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [productsWithLinks, setProductsWithLinks] = useState([]);

  const { selectedBrand, isSelectingVehicle, selectedYear, selectedModel, selectedVersion, modelSeries } = useSelector(state => state.vehicle);

  
  const Categories = (selectedBrand && bikeBrands.includes(selectedBrand.toLowerCase())) ? categoriesBikes : categoriesCars;

  useEffect(() => {
    setLoaded(false);
    if(allProducts.length !== 0) {
      
      filterSearch(props.searchValue)
    }
  }, [allProducts, props.searchValue]);

  useEffect(() => {
    
    const fetchProductLinks = async () => {
      //if(searchProducts.length > 0){
        const productsWithLinks = await Promise.all(searchProducts.map(async item => {
          const foundCategory = await dispatch(getCategoryWithIdProd(Number(item.id_prod)));
          const categoryName = getCategoryNameBySubcategory(foundCategory.category);
          
          let defaultImage = '';
          let measures = {};
          const mappedProduct = new Product(
            item.active,
            item.description,
            item.ds_number,
            item.id_supplier,
            item.images,
            item.sellers,
            measures = {height: item.height, width: item.height, weight: item.height},
            item.listofPropertiesbyCar,
          );

          return mappedProduct;
        }));

        dispatch({
          type: GET_PARTS_LIST_FROM_WH,
          payload: productsWithLinks,
        });

        //setLoaded(true);
        setProductsWithLinks(productsWithLinks);
      }
    //};
    
    fetchProductLinks();
  }, [searchProducts, dispatch]);

  useEffect(() => {
    if(productsWithLinks.length !== 0) {
      setLoaded(true)
    } else if(productsWithLinks.length == 0 && searchProducts.length == 0){
      setTimeout(() => {
        setLoaded(true);
      }, 5000);
    }
  }, [productsWithLinks]);

  const getCategoryNameBySubcategory = (subcategory) => {
    for (const [category, subcategories] of Object.entries(bikeBrands.includes(selectedBrand.toLowerCase()) ? subcatPeloNomeCategoriaBikes : subcatPeloNomeCategoria)) {
      if (subcategories.includes(subcategory)) {
        return category;
      }
    }
    return null;
  };

  const openDialog = (brand) => () => {
    setSelectedBrand(brand); // Save the brand logo to state
    setSelectedBrandLogo(BrandsList[brand]); // Save the brand logo to state
    setOpenedDialog(true);
  };

  const closeDialog = (brand) => {
    setSelectedBrand(brand); // Save the brand logo to state
    setSelectedBrandLogo(BrandsList[brand]); // Save the brand logo to state
    setOpenedDialog(false);
    props.clearAutocomplete();
  };

  const handleItemClick = (type, data) => {
    props.onAutoCompleteClick(type, data); // Trigger the passed function with parameters
  };

  const allCarParts = (allOEs) => {
    const allIDs = Object.entries(Categories)
      .flatMap(([category, image]) => getCategoriesIds(category));

    const uniqueIDs = [...new Set(allIDs)];

    loadParts(vehicle, uniqueIDs, true, "all", allOEs);
  }

  const getCategoriesIds = (name) => {
    const subCategories = bikeBrands.includes(selectedBrand.toLowerCase()) ? subcatPeloNomeCategoriaBikes[name] : subcatPeloNomeCategoria[name];
    const listofIds = [];
    subCategories.forEach((element) => {
        listofIds.push(...idsSubcategoriaPeloNome[element]);
    });

    return listofIds;
  }

  const loadParts = async (car, ids, starting, subcategory, allOEs) => {
    const partsList = [];

    ids.forEach((categoryId) => {
        allOEs.parts.forEach((oe) => {
            if (oe.id_prod === categoryId.toString()) {
              if (!partsList.includes(oe.oe_gpm)) partsList.push([oe.oe_gpm]);
            }
        });
    });

    const distinctIds = partsList;

    const axle = categoryAxle[subcategory.toString()];

    const requestPayload = {
        data: distinctIds,
        cross: false,
    };

    if (configData.defaultStore === 'UmuaramaHD') {
        requestPayload.brand = 'GENUINAHARLEYDAVIDSON';
    } else {
        requestPayload.axle = axle;
        requestPayload.carID = car.InternalID.toString();
    }

    const WHparts = await getPartsListFromWH(requestPayload);

    setAllProducts(WHparts);
  }

  const getPartsListFromWH = async (info) => {
    const functions = getFunctions();
    const callableGetPartsListFromWH = httpsCallable(functions, `${configData.defaultStore === 'UmuaramaHD' ? 'getPartsListFromBrand' : 'getPartsListFromWH'}`);

    const parts = await callableGetPartsListFromWH(info).then((result) => {
        let i = 0;
        const listofArticles = [];
        result.data.forEach((document) => {
            i = i + 1;
            if (document != null) {
                const listOfSellers = document["sellersList"];
                const growableList = [];
                const storeprice = defaultStore === "GoParts" ? "price_ecommerce_gp" : "price_ecommerce_seller";

                listOfSellers.forEach((element) => {
                    if (storeSellersWarehouses.includes(element)) {
                        growableList.push({
                            id: document["id"].toString(),
                            active: document[element]["active"],
                            name: document[element]["name"],
                            price: document[element]["price"],
                            stock: document[element]["stock"],
                            price_ecommerce: document[element].hasOwnProperty(storeprice)
                            ? document[element][storeprice]
                            : document[element]["price"],
                            distance: 1
                        });
                    }
                });

                growableList.sort((a, b) => {
                    var mina = a.price_ecommerce < a.price ? a.price_ecommerce : a.price;
                    var minb = b.price_ecommerce < b.price ? b.price_ecommerce : b.price;

                    if (mina < minb) return -1;

                    if (minb < mina) return 1;
                    return 0;
                });

                listofArticles.push({
                    id: document['id'],
                    active: document['active'],
                    description: document['desc'],
                    ds_number: document['ds_number'],
                    ds_number_gp: document['ds_number_gp'],
                    id_prod: document['id_prod'],
                    id_supplier: document['id_supplier'],
                    images: document['imhash'],
                    listofPropertiesbyCar: document.hasOwnProperty('prop_car')
                        ? document['prop_car']
                        : null,
                    priority: document['priority'],
                    ds_numbers: document['ds_numbers'],
                    weight: document.hasOwnProperty('pesoG')
                        ? document['pesoG']
                        : -1,
                    height: document.hasOwnProperty('altCM')
                        ? document['altCM']
                        : -1,
                    depth: document.hasOwnProperty('profCM')
                        ? document['profCM']
                        : -1,
                    width: document.hasOwnProperty('largCM')
                        ? document['largCM']
                        : -1,
                    sellers: growableList,
                });
            }
        });

        if (listofArticles.length === 0) {
            listofArticles.push({
                id: "0",
                active: false,
                description: "Sem cadastro de peça",
                ds_number: "Sem cadastro de peça",
                ds_number_gp: "Sem cadastro de peça",
                id_prod: "Sem cadastro de peça",
                id_supplier: "0",
                images: [],
                sellers: [],
                listofPropertiesbyCar: {},
                priority: 0,
                weight: -1,
                height: -1,
                depth: -1,
                width: -1,
                ds_numbers: [],
            });
        }

        listofArticles.sort((a, b) => {
            const totalA = a.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
            const totalB = b.sellers.reduce((tot, item) => tot + Number(item.stock), 0);
            return totalB - totalA;
        });

        return listofArticles;
    }).catch((error) => {
      console.error(error);
    });

    return parts
  }

  const normalizeString = (str) => {
    if (typeof str !== 'string') {
      return ''; // Return an empty string or handle it as needed
    }
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  };
  
  const filterSearch = (searchValue) => {
    const normalizedSearchValue = normalizeString(searchValue);
    let filteredProducts = allProducts.filter(item => 
      normalizeString(item.ds_number_gp).includes(normalizedSearchValue)
    );
  
    if (filteredProducts.length > 0) {
      setSearchProducts(filteredProducts);
    } else {
      // No need to redeclare filteredProducts here, just assign it
      const normalizedSearchValue2 = normalizeString(searchValue);
      filteredProducts = allProducts.filter(item => 
        normalizeString(item.description).includes(normalizedSearchValue2)
      );
      setSearchProducts(filteredProducts);
    }
  };
  

  return (
    <div className='autoCompleteContainer'>
      {filteredAndSortedBrands.length > 0 && (
        <div className='column center'
          // style={{padding: '20px'}}
        >
          {/* <h3>Marcas:</h3> */}
          <div className='row' style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
            {filteredAndSortedBrands.map(filteredBrand => (
              <div key={filteredBrand} className='headerFilterOption'
                // onClick={openDialog(filteredBrand)}
                onClick={() => (handleItemClick('brand', filteredBrand))}
              >
                <img src={BrandsList[filteredBrand]} className='headerFilterBrandLogo' alt={filteredBrand} />
              </div>
            ))}
          </div> 
        </div>
      )}

      {Object.keys(vehicle).length !== 0 && 
        <div className='resultsMain'>
          <h3 style={{padding: '10px 0'}}>Resultados para {vehicle.model} {vehicle.version}: </h3>
          <div className='results' style={{marginBottom: '20px'}}>
            {loaded ? 
              (
                productsWithLinks.length > 0 ? (
                  productsWithLinks.map(item => {
                    return (
                      <ProductTile
                        product={item}
                        defaultImage={item.defaultImage}
                        onClick={() => props.clearAutocomplete()}
                        vehicle={vehicle}
                        // isSmall={true}
                      />
                    )
                  })
                ) : "Não há resultados para essa pesquisa!"
              )
              : <CircularProgress
                  sx={{
                    color: 'white'
                  }}
                />
            }
          </div>
        </div>
      }
      {/* {openedDialog && selectedBrandLogo &&
        <Dialog
          type='selectYear'
          brand={selectedBrand}
          brandLogo={selectedBrandLogo}
          closeOnClick={closeDialog}
        />
      } */}

    </div>
  );
}

export default AutoComplete;